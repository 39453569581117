import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InterRegionalConfigService {
    Dconfig: any;
    bulkDeliveryConfig: BehaviorSubject<any>;
    phlenuCommision



    constructor(private _httpClient: HttpClient) {
        this.bulkDeliveryConfig = new BehaviorSubject({});
    }

    /**
      * Resolver
      *
      * @param {ActivatedRouteSnapshot} route
      * @param {RouterStateSnapshot} state
      * @returns {Observable<any> | Promise<any> | any}
      */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getAllBulkConfig(),
                this.getPhlenuCommision()

            ]).then(
                () => {
                    resolve('');
                },
                (error) => {
                    console.log('Error in navigation :', error);
                    alert('Sorry!! Cannot navigate to  section at the moment !! ');
                    reject();
                }
            );
        });
    }


    /**
     * Get bulk config
     *
     * @returns {Promise<any>}
     */
    getAllBulkConfig(): Promise<any> {
        //   TODO: take token from local storage 
        var reqHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'x-access-token': localStorage.getItem('userToken')
        });
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'api/v12/admin/get-all-inter-regional-config', { headers: reqHeaders }).subscribe((response: any) => {
                // TODO: ORIGINAL LOGIC TO FIND ID
                this.Dconfig = response.data;
                this.bulkDeliveryConfig.next(this.Dconfig);
                resolve(this.Dconfig);
            }, reject);
        });
    }


    getPhlenuCommision(): Promise<any> {
        //   TODO: take token from local storage 
        const reqHeaders = new HttpHeaders({
            'x-access-token': localStorage.getItem('userToken')
        });

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'api/v12/admin/get-driver-phelnu-commision', { orderType: 3 }, { headers: reqHeaders }).subscribe((response: any) => {
                // TODO: ORIGINAL LOGIC TO FIND ID
                console.log("response getAdminDriverBonus ===>", response.data);
                this.phlenuCommision = response.data;
                resolve(response);
            }, reject);
        });
    }


    savePhlenuCommision(body){
        const reqHeaders = new HttpHeaders({
        'x-access-token': localStorage.getItem('userToken')
        }); 
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.apiURL + 'api/v12/admin/update-driver-phelnu-commision', body, {headers:reqHeaders})
            .subscribe((response: any) => {  
                resolve(response);
            }, reject); 
                
        }); 
    }


    /** 
     * 
     * @returns {Promise<any>}
     * 
    */
    updateBulkConfig(data) {
        //   TODO: take token from local storage 
        const reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8',
            'x-access-token': localStorage.getItem('userToken')
        });
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'api/v12/admin/update-inter-Regional-config', data, { headers: reqHeaders }).subscribe((response: any) => {
                // TODO: ORIGINAL LOGIC TO FIND ID
                this.Dconfig = response.data;
                this.bulkDeliveryConfig.next(this.Dconfig);
                resolve(response);
            }, reject);
        });
    }


}
