import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { accessDeniedLabel, decrypt, permissionType, shift } from '@fuse/utils/enums';
import { AuthService } from 'app/main/pages/auth.service';

@Component({
  selector: 'app-announcement-config',
  templateUrl: './announcement-config.component.html',
  styleUrls: ['./announcement-config.component.scss','../../../../../../../node_modules/quill/dist/quill.core.css', '../../../../../../../node_modules/quill/dist/quill.bubble.css', '../../../../../../../node_modules/quill/dist/quill.snow.css']
})
export class AnnouncementConfigComponent implements OnInit {

  editMode: boolean = false;
  contentForm: FormGroup;
  actionPermission = '';
  public permissionType = permissionType;
  accessDeniedLabel = accessDeniedLabel;


  labelColorInfo = '#fff';
  enableFieldColorInfo = '#fff';

  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link']
      ]
    }
  }


  constructor(
    private _fb: FormBuilder,
    private _AuthService: AuthService,
    private _CommonService: CommonService,
    private _progressBar: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
  ) {
    this._AuthService.actionPermissionUpdate.subscribe((res) => {
      if (res) {
        this.actionPermission = res;
      } else {
        if (decrypt(localStorage.getItem('sdffhvv'), shift) == 'Read/Write') {
          this.actionPermission = 'Read/Write';
        } else {
          this.actionPermission = null;
        }
      }
    })

  }


  ngOnInit(): void {
    // console.log("createform--==>", this.data);
    // if(this.data){
    this.createForm();
    // }else{
    this.getContentData();

    // }
  }

  quillContentChanged(event) {
    // console.log(this.editor);
    console.log(event);
  }


  createForm(data?) {
    this.contentForm = this._fb.group({
      title: new FormControl({ value: data?.heading, disabled: true }, [Validators.required]),
      content: new FormControl({ value: data?.content, disabled: true }, [Validators.required])
    })
  }

  onEnableForm() {
    this.contentForm.enable();
    this.contentForm.get('title').enable();
    this.contentForm.get('content').enable();
    this.editMode = true;
  }

  onDisableForm() {
    this.contentForm.disable();
    this.contentForm.get('title').disable();
    this.contentForm.get('content').disable();
    this.editMode = false;
  }

  onSaveContent() {
    console.log("this.fomr", this.contentForm.value);
    const payload = {
      heading: this.contentForm.value.title,
      content: this.contentForm.value.content,
      id: 2,
    }
    console.log("payload--->", this.contentForm.value.content);
    this._progressBar.show();
    this._CommonService.updateAffiliateContent(payload).subscribe((res: any) => {
      this._progressBar.hide();
      if (res.response == 1) {
        this.onDisableForm();
        this._matSnackBar.open(res.message, 'OKAY', { duration: 3000 });
      } else {
        this._matSnackBar.open(res.message, 'OKAY', { duration: 3000 });
      }
    })
  }

  getContentData() {
    this._progressBar.show();
    this._CommonService.getAffiliateContent().subscribe((res: any) => {
      this._progressBar.hide();
      if (res.response == 1) {
        this.createForm(res.data.filter((item) => item.id == 2)[0]);
      } else {
        this._matSnackBar.open(res.message, 'OKAY', { duration: 3000 });
      }
    })
  }

}
