import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TiersManageComponent } from './tiers-manage/tiers-manage.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { AffiliatesContentTabsComponent } from './affiliates-content-tabs/affiliates-content-tabs.component';
import { QuillModule } from 'ngx-quill';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { AffiliateToolkitComponent } from './affiliates-content-tabs/tabs/affiliate-toolkit/affiliate-toolkit.component';
import { AnnouncementConfigComponent } from './affiliates-content-tabs/tabs/announcement-config/announcement-config.component';
import { AffiliateTermsAndConditionsComponent } from './affiliates-content-tabs/tabs/affiliate-terms-and-conditions/affiliate-terms-and-conditions.component';
import { AffiliateGuidelinesComponent } from './affiliates-content-tabs/tabs/affiliate-guidelines/affiliate-guidelines.component';


const routes: Routes = [
  {
    path      : 'tiers-update',
    component : TiersManageComponent,
  },
  {
    path      : 'content',
    component : AffiliatesContentTabsComponent,
    children  : [
      {
        path  : '',
        loadChildren : () => import('./affiliates-content-tabs/tabs/social-media-graphics/social-media-graphics.module').then(m => m.SocialMediaGraphicsModule)
      }
    ]
  },
  
];

@NgModule({
  declarations: [TiersManageComponent, AffiliatesContentTabsComponent, AffiliateToolkitComponent, AnnouncementConfigComponent, AffiliateTermsAndConditionsComponent, AffiliateGuidelinesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    QuillModule.forRoot(),
    FuseSharedModule,
    MatIconModule,
    FuseSidebarModule,
    RouterModule,
    MatSnackBarModule,
    MatStepperModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
  ]
})
export class AffiliatesTiersManageModule { }