import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { countriesFilters } from "@fuse/utils/enums";

@Component({
    selector: "app-common-advanced-filter",
    templateUrl: "./common-advanced-filter.component.html",
    styleUrls: ["./common-advanced-filter.component.scss"],
})
export class CommonAdvancedFilterComponent implements OnInit {
    countriesFilters = JSON.parse(JSON.stringify(countriesFilters));
    form: FormGroup;
    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: any,
        public matDialogRef: MatDialogRef<CommonAdvancedFilterComponent>,
        private _fb: FormBuilder
    ) {}

    ngOnInit(): void {
        if (!this._data?.allowOptions?.isBothCountryOption) {
            this.countriesFilters = this.countriesFilters.filter(
                (item) => item.id !== 3
            );
        }
        this.createFrom();
    }

    createFrom() {
        this.form = this._fb.group({
            country: [
                this._data.optionData.country !== 0
                    ? this._data.optionData.country
                    : "",
            ],
            status: [this._data.optionData.statusValue || ""],
        });
    }

    clearFilter() {}

    onSave() {
        console.log("this.form.value", this.form.value);
        this.matDialogRef.close(this.form.value);
    }

    onResetAllFilter() {
        this._data.optionData.country = 0;
        this.form.reset();
        this.matDialogRef.close({ reset: true });
    }
}
