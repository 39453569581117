import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAdvancedFilterComponent } from './common-advanced-filter/common-advanced-filter.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TfaVerificationDialogComponent } from './tfa-verification-dialog/tfa-verification-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { OtpFormComponent } from './otp-form/otp-form.component';



@NgModule({
  declarations: [CommonAdvancedFilterComponent, TfaVerificationDialogComponent, OtpFormComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
  ]
})
export class DialogModule { }
