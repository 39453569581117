import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { accessDeniedLabel, decrypt, permissionType, shift } from '@fuse/utils/enums';
import { AuthService } from 'app/main/pages/auth.service';

@Component({
  selector: 'app-tiers-manage',
  templateUrl: './tiers-manage.component.html',
  styleUrls: ['./tiers-manage.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class TiersManageComponent implements OnInit {
  coverPic: any = '../../../assets/images/backgrounds/default-cover.jpg';
  selectedIndex = 0;
  labelColorInfo:any = '#ccc';
  disableFieldColorInfo:any = 'white';
  enableFieldColorInfo:any = 'white';

  
  actionPermission = '';
  public permissionType = permissionType;
  accessDeniedLabel = accessDeniedLabel;

  data = [
    // ghana
    {
      id : 1,
      country : 'Ghana',
      title : 'Starter Star',
      isEditMode : 0,
      commissionField : 'ghSScommision',
      timeField : 'ghSStime',
      referralsField : 'ghSSReferrals',
      bonusField : 'ghSSBonus',
    },
    {
      id : 2,
      country : 'Ghana',
      title : 'Pro Promoter',
      isEditMode : 0,
      commissionField : 'ghPPcommision',
      timeField : 'ghPPtime',
      referralsField : 'ghPPReferrals',
      bonusField : 'ghPPBonus',
    },
    {
      id : 3,
      country : 'Ghana',
      title : 'Elite Influencer',
      isEditMode : 0,
      commissionField : 'ghEIcommision',
      timeField : 'ghEItime',
      referralsField : 'ghEIReferrals',
      bonusField : 'ghEIBonus',
    },
    // {
    //   id : 4,
    //   country : 'Ghana',
    //   title : 'Ongoing Earnings',
    //   isEditMode : 0,
    //   commissionField : 'ghOEcommision',
    //   timeField : 'ghOEtime',
    //   referralsField : 'ghOEReferrals',
    //   bonusField : 'ghOEBonus',
    // },
    
    // Nigeria
    {
      id : 5,
      country : 'Nigeria',
      title : 'Starter Star',
      isEditMode : 0,
      commissionField : 'ngSScommision',
      timeField : 'ngSStime',
      referralsField : 'ngSSReferrals',
      bonusField : 'ngSSBonus',
    },
    {
      id : 6,
      country : 'Nigeria',
      title : 'Pro Promoter',
      isEditMode : 0,
      commissionField : 'ngPPcommision',
      timeField : 'ngPPtime',
      referralsField : 'ngPPReferrals',
      bonusField : 'ngPPBonus',
    },
    {
      id : 7,
      country : 'Nigeria',
      title : 'Elite Influencer',
      isEditMode : 0,
      commissionField : 'ngEIcommision',
      timeField : 'ngEItime',
      referralsField : 'ngEIReferrals',
      bonusField : 'ngEIBonus',
    },
    // {
    //   id : 8,
    //   country : 'Nigeria',
    //   title : 'Ongoing Earnings',
    //   isEditMode : 0,
    //   commissionField : 'ngOEcommision',
    //   timeField : 'ngOEtime',
    //   referralsField : 'ngOEReferrals',
    //   bonusField : 'ngOEBonus',
    // },
  ]

  form:FormGroup;
  
  constructor(
    private _fb: FormBuilder,
    private _CommonService : CommonService,
    private _AuthService : AuthService,
    private _matSnackBar : MatSnackBar,
    private _fuseProgressBar : FuseProgressBarService
  ) { 
    this.getTiersData();

    this._AuthService.actionPermissionUpdate.subscribe((res)=>{ 
      if(res){
          this.actionPermission = res;
      }else{
          if(decrypt(localStorage.getItem('sdffhvv'), shift) == 'Read/Write'){
              this.actionPermission = 'Read/Write';
          }else{
              this.actionPermission = null;
          }
      }
    })


  }

  ngOnInit(): void {
    this.createForm();
  }

  
  getTiersData(){
    this._fuseProgressBar.show();
    this._CommonService.getTiersData().subscribe((res:any) =>{
      if(res.response == 1){
        this._fuseProgressBar.hide();
        this.setFormValue(res.data)
      }
    })
  }


  createForm(data?){
    console.log("data",data);
    this.form = this._fb.group({

      ghSScommision : [{value: '', disabled : true}, [Validators.required, Validators.min(0), Validators.max(100)]],
      ghSStime : [{value:'', disabled : true}, [Validators.required,  Validators.min(1), Validators.max(24)]],
      ghSSReferrals : [{value:'', disabled : true},[Validators.required, Validators.min(1)]],
      ghSSBonus : [{value:'', disabled : true},[Validators.required, Validators.min(1)]],

      ghPPcommision : [{value:'', disabled : true},[Validators.required, Validators.min(0), Validators.max(100)]],
      ghPPtime : [{value:'', disabled : true},[Validators.required, Validators.min(1), Validators.max(24)]],
      ghPPReferrals : [{value:'', disabled : true},[Validators.required, Validators.min(1)]],
      ghPPBonus : [{value:'', disabled : true},[Validators.required, Validators.min(1)]],
      
      ghEIcommision : [{value:'', disabled : true},[Validators.required, Validators.min(0), Validators.max(100)]],
      ghEItime : [{value:'', disabled : true},[Validators.required, Validators.min(1), Validators.max(24)]],
      ghEIReferrals : [{value:'', disabled : true},[Validators.required, Validators.min(1)]],
      ghEIBonus : [{value:'', disabled : true},[Validators.required, Validators.min(1)]],
      
      // ghOEcommision : [{value:'', disabled : true},[Validators.required, Validators.min(0), Validators.max(100)]],
      // ghOEtime : [{value:'', disabled : true},[Validators.required, Validators.min(1), Validators.max(24)]],
      // ghOEReferrals : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      // ghOEBonus : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],

      
      ngSScommision : [{value:'', disabled : true},[Validators.required, Validators.min(0), Validators.max(100)]],
      ngSStime : [{value:'', disabled : true},[Validators.required, Validators.min(1), Validators.max(24)]],
      ngSSReferrals : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      ngSSBonus : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],

      ngPPcommision : [{value:'', disabled : true},[Validators.required, Validators.min(0), Validators.max(100)]],
      ngPPtime : [{value:'', disabled : true},[Validators.required, Validators.min(1), Validators.max(24)]],
      ngPPReferrals : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      ngPPBonus : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      
      ngEIcommision : [{value:'', disabled : true},[Validators.required, Validators.min(0), Validators.max(100)]],
      ngEItime : [{value:'', disabled : true},[Validators.required, Validators.min(1), Validators.max(24)]],
      ngEIReferrals : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      ngEIBonus : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      
      // ngOEcommision : [{value:'', disabled : true},[Validators.required, Validators.min(0), Validators.max(100)]],
      // ngOEtime : [{value:'', disabled : true},[Validators.required, Validators.min(1), Validators.max(24)]],
      // ngOEReferrals : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      // ngOEBonus : [{value:'', disabled : true}, [Validators.required, Validators.min(1)]],
      
    })
  }


  onChangeMode(item){
    if(item.id == 1){
      if(item.isEditMode){
        this.form.get('ghSScommision').disable();
        this.form.get('ghSStime').disable();
        this.form.get('ghSSReferrals').disable();
        this.form.get('ghSSBonus').disable();
      }else{
        this.form.get('ghSScommision').enable();
        this.form.get('ghSStime').enable();
        this.form.get('ghSSReferrals').enable();
        this.form.get('ghSSBonus').enable();
      }
    }else if(item.id == 2){
      if(item.isEditMode){
        this.form.get('ghPPcommision').disable();
        this.form.get('ghPPtime').disable();
        this.form.get('ghPPReferrals').disable();
        this.form.get('ghPPBonus').disable();
      }else{
        this.form.get('ghPPcommision').enable();
        this.form.get('ghPPtime').enable();
        this.form.get('ghPPReferrals').enable();
        this.form.get('ghPPBonus').enable();
      }
    }else if(item.id == 3){
      if(item.isEditMode){
        this.form.get('ghEIcommision').disable();
        this.form.get('ghEItime').disable();
        this.form.get('ghEIReferrals').disable();
        this.form.get('ghEIBonus').disable();
      }else{
        this.form.get('ghEIcommision').enable();
        this.form.get('ghEItime').enable();
        this.form.get('ghEIReferrals').enable();
        this.form.get('ghEIBonus').enable();
      }
    }else if(item.id == 4){
      if(item.isEditMode){
        // this.form.get('ghOEcommision').disable();
        // this.form.get('ghOEtime').disable();
        // this.form.get('ghOEReferrals').disable();
        // this.form.get('ghOEBonus').disable();
      }else{
        // this.form.get('ghOEcommision').enable();
        // this.form.get('ghOEtime').enable();
        // this.form.get('ghOEReferrals').enable();
        // this.form.get('ghOEBonus').enable();
      }
    }else if(item.id == 5){
      if(item.isEditMode){
        this.form.get('ngSScommision').disable();
        this.form.get('ngSStime').disable();
        this.form.get('ngSSReferrals').disable();
        this.form.get('ngSSBonus').disable();
      }else{
        this.form.get('ngSScommision').enable();
        this.form.get('ngSStime').enable();
        this.form.get('ngSSReferrals').enable();
        this.form.get('ngSSBonus').enable();
      }
    }else if(item.id == 6){
      if(item.isEditMode){
        this.form.get('ngPPcommision').disable();
        this.form.get('ngPPtime').disable();
        this.form.get('ngPPReferrals').disable();
        this.form.get('ngPPBonus').disable();
      }else{
        this.form.get('ngPPcommision').enable();
        this.form.get('ngPPtime').enable();
        this.form.get('ngPPReferrals').enable();
        this.form.get('ngPPBonus').enable();
      }
    }else if(item.id == 7){
      if(item.isEditMode){
        this.form.get('ngEIcommision').disable();
        this.form.get('ngEItime').disable();
        this.form.get('ngEIReferrals').disable();
        this.form.get('ngEIBonus').disable();
      }else{
        this.form.get('ngEIcommision').enable();
        this.form.get('ngEItime').enable();
        this.form.get('ngEIReferrals').enable();
        this.form.get('ngEIBonus').enable();
      }
    }else if(item.id == 8){
      if(item.isEditMode){
        // this.form.get('ngOEcommision').disable();
        // this.form.get('ngOEtime').disable();
        // this.form.get('ngOEReferrals').disable();
        // this.form.get('ngOEBonus').disable();
      }else{
        // this.form.get('ngOEcommision').enable();
        // this.form.get('ngOEtime').enable();
        // this.form.get('ngOEReferrals').enable();
        // this.form.get('ngOEBonus').enable();
      }
    }
    
    if(item.isEditMode == 1) item.isEditMode = 0 
    else item.isEditMode = 1
   
    console.log("item.isEditMode", item.isEditMode);
    if(!item.isEditMode)
    this.saveTiers(item)

  }

  saveTiers(item){
    const payload =  {
      id : item.id,
      commission: this.form.get(item.commissionField).value, 
      bonusAmt : this.form.get(item.bonusField).value, 
      referralCount : this.form.get(item.referralsField).value, 
      duration : this.form.get(item.timeField).value
    } 
    this._fuseProgressBar.show();
    this._CommonService.updateTiersPlans(payload).subscribe((res:any)=>{
      if(res.response == 1){
        this._fuseProgressBar.hide();
        this._matSnackBar.open(res.messages, 'OKAY', {duration : 2000})
      }
    })
  }


  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    // console.log("tabChangeEvent ===>",tabChangeEvent.index);
    this.selectedIndex = tabChangeEvent.index
  }


  trackByIndex(index: number, obj: any): any {
    return index;
  }

  setFormValue(data){
    this.data.forEach((item:any) => {
      data.forEach((field:any) =>{
        if(item.id == field.id && item.country == field.country){
          this.form.get(item.commissionField).setValue(String(field.commission))
          this.form.get(item.bonusField).setValue(String(field.bonusAmt))
          this.form.get(item.referralsField).setValue(String(field.referralCount))
          this.form.get(item.timeField).setValue(String(field.duration))
        }
      })
    })
  }


  
}
