<div class="affiliatesTiers page-layout simple tabbed primary-600">

    <!-- Fix the DOM sanitizing Stuff with Background IMAGE -->
    <div class="header p-10" [ngStyle]="{ background: 'url(' + coverPic + ') 0% 0% / auto 100%' }" fxLayout="column"
        fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end">
        <div class="info-section" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end">
            <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="center center">
                

                <!-- <span matBadge="✔" class="badge_min" matBadgeOverlap="true" matBadgeDescription="Verified"></span> -->

                <div class="name" fxLayout="column" fxLayoutAlign="center" [@animate]="{
                     value: '*',
                     params: { delay: '100ms', x: '-25px' }
                 }">
                    <div class="business_name">
                        <h1>Affiliates Tiers Manage</h1>
                    </div> 
                </div>
            </div> 
        </div>
    </div>
    <!-- / HEADER -->


    <div class="contentTiers">
        <mat-tab-group dynamicHeight="true" (focusChange)="tabChanged($event)">
            <form [formGroup]="this.form">
                <mat-tab label="Ghana">
                    <div class="grid-wrapper">
                        <ng-container *ngFor="let item of data;let index = index;trackBy:trackByIndex;">
                            <ng-container *ngIf="item.country == 'Ghana'">
                                <div class="grid-item">
                                    <div class="titleHeader">
                                        <h4>{{item.title | titlecase}}</h4>
                                        <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                                            <button [matTooltip]="item.isEditMode == 1 ? 'Save' : 'Edit'" mat-icon-button (click)="onChangeMode(item)">
                                                <mat-icon *ngIf="item.isEditMode == 0">edit</mat-icon>
                                                <mat-icon *ngIf="item.isEditMode == 1">done</mat-icon>
                                            </button>
                                        </ng-container>
                                    </div>
                                    <div class="body-wrapper">

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Commission (%)</mat-label>
                                            <input style="caret-color: #ddd;" type="number" [formControlName]="item.commissionField" matInput placeholder="commission percent" [style.color]="enableFieldColorInfo" [min]="0" [max]="100">
                                            <mat-error>{{this.form.get(item.commissionField).hasError('required') ? 'Commission is required' : 'Invalid Value'}}</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Duration in months</mat-label>
                                            <input style="caret-color: #ddd;" [formControlName]="item.timeField" matInput placeholder="Duration in months" [min]="1" [max]="24"
                                                [style.color]="enableFieldColorInfo">
                                            <!-- <mat-hint [style.color]="'#E8B86D'">Hint: Duration should be within 1 to 24 months</mat-hint> -->
                                            <mat-error>{{this.form.get(item.timeField).hasError('required') ? 'Duration months is required' : 'Duration should be within 1 to 24 months'}}</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Referral count</mat-label>
                                            <input style="caret-color: #ddd;" [formControlName]="item.referralsField" matInput placeholder="Enter referrals count" [min]="1"
                                                [style.color]="enableFieldColorInfo">
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Bonus</mat-label>
                                            <input style="caret-color: #ddd;" [formControlName]="item.bonusField"  matInput placeholder="Etner bonus" [min]="1"
                                                [style.color]="enableFieldColorInfo">
                                        </mat-form-field>

                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </mat-tab>
                <mat-tab label="Nigeria">
                    <div class="grid-wrapper">
                        <ng-container *ngFor="let item of data;let index = index;trackBy:trackByIndex;">
                            <ng-container *ngIf="item.country == 'Nigeria'">
                                <div class="grid-item">
                                    <div class="titleHeader">
                                        <h4>{{item.title | titlecase}}</h4>
                                        <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                                            <button [matTooltip]="item.isEditMode == 1 ? 'Save' : 'Edit'" mat-icon-button (click)="onChangeMode(item)">
                                                <mat-icon *ngIf="item.isEditMode == 0">edit</mat-icon>
                                                <mat-icon *ngIf="item.isEditMode == 1">done</mat-icon>
                                            </button>
                                        </ng-container>
                                    </div>
                                    <div class="body-wrapper">

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Commission (%)</mat-label>
                                            <input style="caret-color: #ddd;" type="number" [formControlName]="item.commissionField" matInput placeholder="commission percent" [style.color]="enableFieldColorInfo" [min]="0" [max]="100">
                                            <mat-error>{{this.form.get(item.commissionField).hasError('required') ? 'Commission is required' : 'Invalid Value'}}</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Duration in months</mat-label>
                                            <input style="caret-color: #ddd;" [formControlName]="item.timeField" matInput placeholder="Duration in months" [min]="1" [max]="24"
                                                [style.color]="enableFieldColorInfo">
                                            <!-- <mat-hint [style.color]="'#E8B86D'">Hint: Duration should be within 1 to 24 months</mat-hint> -->
                                            <mat-error>{{this.form.get(item.timeField).hasError('required') ? 'Duration months is required' : 'Duration should be within 1 to 24 months'}}</mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Order count</mat-label>
                                            <input style="caret-color: #ddd;" [formControlName]="item.referralsField" matInput placeholder="Enter referrals" [min]="1"
                                                [style.color]="enableFieldColorInfo">
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" class="pl-4">
                                            <mat-label [style.color]="labelColorInfo">Bonus</mat-label>
                                            <input style="caret-color: #ddd;" [formControlName]="item.bonusField"  matInput placeholder="Etner bonus" [min]="1"
                                                [style.color]="enableFieldColorInfo">
                                        </mat-form-field>

                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </mat-tab>
            </form>
        </mat-tab-group>
    </div>

</div>