import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { CommonService } from "@fuse/services/common.service";
import { accessDeniedLabel, decrypt, permissionType, shift } from "@fuse/utils/enums";
import { AuthService } from "app/main/pages/auth.service";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "app-affiliate-terms-and-conditions",
    templateUrl: "./affiliate-terms-and-conditions.component.html",
    styleUrls: ["./affiliate-terms-and-conditions.component.scss",'../../../../../../../node_modules/quill/dist/quill.core.css', '../../../../../../../node_modules/quill/dist/quill.bubble.css', '../../../../../../../node_modules/quill/dist/quill.snow.css'],
})
export class AffiliateTermsAndConditionsComponent implements OnInit {
    editMode: boolean = false;
    contentForm: FormGroup;
    actionPermission = "";
    public permissionType = permissionType;
    accessDeniedLabel = accessDeniedLabel;
    @Input() data: BehaviorSubject<string>; // this is the html

    @ViewChild('matEditor', { static: true })
    matEditor: any;


    labelColorInfo = "#fff";
    enableFieldColorInfo = "#fff";

    quillConfig = {
        //toolbar: '.toolbar',
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],

                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction

                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],

                ["clean"], // remove formatting button

                ["link"],
            ],
        },
    };

    constructor(
        private _fb: FormBuilder,
        private _AuthService: AuthService,
        private _CommonService: CommonService,
        private sanitizer: DomSanitizer,
        private _progressBar: FuseProgressBarService,
        private _matSnackBar: MatSnackBar
    ) {



      this._AuthService.actionPermissionUpdate.subscribe((res) => {
            if (res) {
              this.actionPermission = res;
            } else {
              if (decrypt(localStorage.getItem('sdffhvv'), shift) == 'Read/Write') {
                this.actionPermission = 'Read/Write';
              } else {
                this.actionPermission = null;
              }
            }
          })
      
     
    }

    ngOnInit(): void {
      this.createForm();
      // }else{
      this.getContentData();

      this.data.subscribe((data: string) => {
        this.contentForm.get('content').patchValue(data);
        // tried also -> this.form.controls.matEditor.setValue(data);   
    });
    }

    createForm(data?) {
        console.log("data===>", data);
        this.contentForm = this._fb.group({
            title: new FormControl({ value: data?.title, disabled: true }, [
                Validators.required,
            ]),
            content: new FormControl({ value: data?.content, disabled: true }, [
                Validators.required,
            ]),
        });
    }

    onEnableForm() {
        this.contentForm.enable();
        this.contentForm.get("title").enable();
        this.contentForm.get("content").enable();
        this.editMode = true;
    }

    onDisableForm() {
        this.contentForm.disable();
        this.contentForm.get("title").disable();
        this.contentForm.get("content").disable();
        this.editMode = false;
    }

    onSaveContent() {
        console.log("this.fomr", this.contentForm.value);
        const payload = {
            title: this.contentForm.value.title,
            content: this.contentForm.value.content,
            type : 1,
        };
        this._progressBar.show();
        this._CommonService
            .updateAffiliateTermsandGuildeline(payload)
            .subscribe((res: any) => {
                this._progressBar.hide();
                if (res.response == 1) {
                    this.onDisableForm();
                    this._matSnackBar.open(res.message, "OKAY", {
                        duration: 3000,
                    });
                } else {
                    this._matSnackBar.open(res.message, "OKAY", {
                        duration: 3000,
                    });
                }
            });
    }

    quillContentChanged(event) {
        // console.log('Delta:', event.delta); // Log Delta format
        // console.log("event.html", this.sanitizer.bypassSecurityTrustHtml(event.html));
    }

    getContentData() {
        this._progressBar.show();
        this._CommonService.getAffiliateTermsandGuildeline(1).subscribe((res: any) => {
            this._progressBar.hide();
            if (res.response == 1) {
                this.createForm(res.data);
            } else {
                this._matSnackBar.open(res.message, "OKAY", { duration: 3000 });
            }
        });
    }
}
