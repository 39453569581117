import { FuseNavigation } from '@fuse/types';


// Author : Sohan Paliyal
// Naming Guides: 
// Navigation Code Naming Rules 
// id => first character small
// title => first character Capital
// url => same as id 
                        // exactMatch: true


export const navigation: FuseNavigation[] = [
    {
        id       : 'main menu',
        title    : 'Main Menu',
        translate: 'NAV.MAIN MENU',
        type     : 'group',
        children : [
            // {
            //     id       : 'sample',
            //     title    : 'Sample',
            //     translate: 'NAV.SAMPLE.TITLE',
            //     type     : 'item',
            //     icon     : 'email',
            //     url      : '/sample',
            //     badge    : {
            //         title    : '25',
            //         translate: 'NAV.SAMPLE.BADGE',
            //         bg       : '#F44336',
            //         fg       : '#FFFFFF'
            //     }
            // },
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard',
                //exactMatch: true,
            },
            

            {
                id       : 'users',
                title    : 'Users',
                type     : 'collapsable',
                icon     : 'business_center',
                // url      : '',
                // exactMatch: true,
                children : [
                    {
                        id   : 'merchants',
                        title: 'Merchants',
                        type : 'item',
                        icon : 'business_center',
                        url  : '/merchants/all-merchants',
                        exactMatch: true
                    },
                    {
                        id   : 'customers',
                        title: 'Customers',
                        type : 'item',
                        icon : 'wc',
                        url  : '/customers/all',
                        exactMatch: true
                    },
                    {
                        id       : 'drivers',
                        title    : 'Drivers',
                        type     : 'item',
                        icon     : 'airline_seat_recline_extra',
                        url      : 'delivery/drivers',
                        exactMatch: true,  
                    },
                    {
                        id       : 'inWaitingDrivers',
                        title    : 'In Waiting Drivers',
                        type     : 'item',
                        icon     : 'airline_seat_recline_extra',
                        url      : 'delivery/in-waiting',
                        exactMatch: true,
                    },
                    
                    {
                        id       : 'affiliates',
                        title    : 'Affiliates',
                        type     : 'item',
                        icon     : 'group_add',
                        url      : 'affiliates/all',
                        exactMatch: true,
                    },

                    {
                        id       : 'pdasUsers',
                        title    : 'PDAS',
                        type     : 'item',
                        icon     : 'person',
                        url      : 'pdas-users/all',
                        exactMatch: true,

                    },
                    
                    {
                        id       : 'businessPartners',
                        title    : 'Business Partners',
                        type     : 'item',
                        icon     : 'people_alt',
                        url      : 'business-partners/all',
                        exactMatch: true,
                    },
                    
                    {
                        id       : 'pdasOffline',
                        title    : 'PDAS Offline',
                        type     : 'item',
                        icon     : 'offline_bolt',
                        url      : '/pdas-convenience-fee',
                        exactMatch: true,
                    },
                ]
                
            },
           

            {
                id       : 'subadmins',
                title    : 'Sub Admins',
                type     : 'collapsable',
                icon     : 'people',
                // url      : '/subadmins',
                // exactMatch: true,
                children : [
                    {
                        id   : 'subAdminRoles',
                        title: 'Sub Admin Roles',
                        type : 'item',
                        icon : 'people',
                        url  : '/subadmins/roles',
                        exactMatch: true
                    },
                    {
                        id   : 'subAdminStaff',
                        title: 'Sub Admin',
                        type : 'item',
                        icon : 'people',
                        url  : '/subadmins',
                        exactMatch: true
                    },

                ]
            },

            {
                id   : 'phlenuStaff',
                title: 'Phlenu Staff',
                type : 'item',
                icon : 'people',
                url  : '/phlenu-staff',
                exactMatch: true
            },
            {
                id       : 'stats',
                title    : 'Listings',
                type     : 'collapsable',
                icon     : 'assignment',
                children : [
                    {
                        id   : 'allProducts',
                        title: 'All Products',
                        type : 'item',
                        icon : 'shopping_basket',
                        url  : '/product-stats/all-products',
                        exactMatch: true
                    },
                    {
                        id   : 'allDeals',
                        title: 'All Deals',
                        type : 'item',
                        icon : 'local_offer',
                        url  : '/deal-stats/all-deals',
                        exactMatch: true
                    },
                    {
                        id   : 'allEvents',
                        title: 'All Events',
                        type : 'item',
                        icon : 'event',
                        url  : '/event-stats/all-events',
                        exactMatch: true
                    },
                    {
                        id   : 'allServices',
                        title: 'All Services',
                        type : 'item',
                        icon : 'swap_horizontal_circle',
                        url  : '/service-stats/all-services',
                        exactMatch: true
                    },
                    {
                        id   : 'allOrders',
                        title: 'All Orders',
                        type : 'item',
                        icon : 'assignment_returned',
                        url  : '/stats/customer-orders',
                        exactMatch: true
                    },
                    {
                        id   : 'deliveryOrders',
                        title: 'Delivery Orders',
                        type : 'item',
                        icon : 'send',
                        url  : '/stats/delivery-orders',
                        exactMatch: true
                    },
                    {
                        id   : 'bulkOrders',
                        title: 'Bulk Orders',
                        type : 'item',
                        icon : 'list_alt',
                        url  : '/stats/bulk-orders',
                        exactMatch: true
                    },
                    {
                        id   : 'interRegionalOrders',
                        title: 'Inter Regional Orders',
                        type : 'item',
                        icon : 'list_alt',
                        url  : '/stats/inter-regional-orders',
                        exactMatch: true
                    },
                    {
                        id   : 'unavailServiceAreaOrders',
                        title: 'Unavailable Service Area',
                        type : 'item',
                        icon : 'send',
                        url  : '/stats/unavailable-service-area-orders',
                        exactMatch: true
                    },
                    {
                        id   : 'cancelorders',
                        title: 'Cancelled Orders (Driver)',
                        type : 'item',
                        icon : 'send',
                        url  : '/stats/cancelled-orders-by-driver',
                        exactMatch: true
                    },
                    // {
                    //     id   : 'transactions',
                    //     title: 'All Transactions',
                    //     type : 'item',
                    //     icon : 'money',
                    //     url  : '/stats/all-transactions',
                    //     exactMatch: true
                    // },


                    {
                        id       : 'cart',
                        title    : 'Cart',
                        type     : 'item',
                        icon     : 'shopping_basket',
                        url      : '/cart',
                        exactMatch: true,
                    },

                    {
                        id       : 'wishlist',
                        title    : 'Wishlist',
                        type     : 'item',
                        icon     : 'favorite',
                        url      : '/wishlist',
                        exactMatch: true,
                    },

                ]
            },
            {
                id       : 'addon',
                title    : 'Add-on(s) Listing',
                type     : 'collapsable',
                icon     : 'flash_on',
                children : [

                    {
                        id   : 'purchasedEmployeePlans',
                        title: 'Purchased EmployeePlans',
                        type : 'item',
                        icon : 'flash_on',
                        url  : '/addons/purchased-employee-plan',
                        exactMatch: true
                    },
                    {
                        id   : 'purchasedBoosters',
                        title: 'Purchased Boosters',
                        type : 'item',
                        icon : 'flash_on',
                        url  : '/addons/purchased-boosters',
                        exactMatch: true
                    },
                    {
                        id   : 'purchasedSubscriptions',
                        title: 'Purchased Subscriptions',
                        type : 'item',
                        icon : 'flash_on',
                        url  : '/addons/purchased-subscription',
                        exactMatch: true
                    },

                ]
            },
            {
                id       : 'promotion',
                title    : 'Promotions',
                type     : 'collapsable',
                icon     : 'local_play',
                children : [

                    {
                        id   : 'coupons',
                        title: 'Coupons',
                        type : 'item',
                        icon : 'local_play',
                        url  : '/coupons/all',
                        exactMatch: true
                    },
                    {
                        id   : 'promoCode',
                        title: 'PDAS Promo Codes',
                        type : 'item',
                        icon : 'local_play',
                        url  : '/promocode/all',
                        exactMatch: true
                    },
                    {
                        id       : 'promotionsRevenue',
                        title    : 'Driver Promotions',
                        type     : 'item',
                        icon     : 'shopping_basket',
                        url      : '/promotions-revenue/all',
                        exactMatch: true,
                    },
                    {
                        id       : 'pdasDiscountTimeSlots',
                        title    : 'PDAS Discount TimeSlots',
                        type     : 'item',
                        icon     : 'attach_money',
                        url      : '/pdas-discount-timeslots',
                        exactMatch: true,
                    },
                    // {
                    //     id   : 'affiliates',
                    //     title: 'Affiliates',
                    //     type : 'item',
                    //     icon : 'local_play',
                    //     url  : '/affiliates/all',
                    //     exactMatch: true
                    // },

                ]
            },

            {
                id       : 'earning',
                title    : 'Earning',
                type     : 'collapsable',
                icon     : 'list_alt',
                children : [
                    {
                        id   : 'affiliateEarning',
                        title: 'Affiliate Earning',
                        type : 'item',
                        icon : 'money',
                        url  : 'earning/affiliate-earning',
                        exactMatch: true
                    },
                    {
                        id   : 'referralEarning',
                        title: 'Referral Earning',
                        type : 'item',
                        icon : 'money',
                        url  : 'earning/referral-earning',
                        exactMatch: true
                    },
                    {
                        id   : 'referralEarningPDAS',
                        title: 'Referral Earning PDAS',
                        type : 'item',
                        icon : 'money',
                        url  : 'earning/referral-earning-pdas',
                        exactMatch: true
                    },
                    {
                        id   : 'referralEarningDrivers',
                        title: 'Referral Earning Drivers',
                        type : 'item',
                        icon : 'money',
                        url  : 'earning/referral-earning-drivers',
                        exactMatch: true
                    },
                    {
                        id   : 'phlenuCash',
                        title: 'Phlenu Cash PC',
                        type : 'item',
                        icon : 'money',
                        url  : 'earning/phelnu-cash',
                        exactMatch: true
                    },



                ]
            },
            {
                id       : 'kyc',
                title    : 'KYC',
                type     : 'item',
                icon     : 'shopping_basket',
                url      : '/customer-kyc',
                exactMatch: true,
            },



            {
                id       : 'withdrawal',
                title    : 'Withdrawal Requests',
                type     : 'collapsable',
                icon     : 'attach_money',
                // url      : '/withdrawal-requests',
                children : [
                    {
                        id   : 'merchantWithdrawal',
                        title: 'Merchant Requests',
                        type : 'item',
                        icon : 'attach_money',
                        url  : '/withdrawal-requests/merchant',
                        exactMatch: true
                    },
                    {
                        id   : 'driverWithdrawal',
                        title: 'Driver Requests',
                        type : 'item',
                        icon : 'attach_money',
                        url  : '/withdrawal-requests/driver',
                        exactMatch: true
                    },
                    {
                        id   : 'affCommissionWithdrawal',
                        title: 'Affiliate Commission',
                        type : 'item',
                        icon : 'attach_money',
                        url  : '/withdrawal-requests/affiliate-commission',
                        exactMatch: true
                    },
                    {
                        id   : 'pdasWithdrawal',
                        title: 'PDAS Requests',
                        type : 'item',
                        icon : 'attach_money',
                        url  : '/withdrawal-requests/pdas',
                        exactMatch: true
                    },
                
                ]
            },        

            {
                id       : 'notifyUsers',
                title    : 'Notify Users',
                type     : 'collapsable',
                icon     : 'notifications_active',
                children : [
                    {
                        id   : 'notifyMerchant',
                        title: 'Notify Merchants',
                        type : 'item',
                        icon : 'send',
                        url  : '/notify-user/merchants',
                        exactMatch: true
                    },
                    {
                        id   : 'notifyCustomer',
                        title: 'Notify Customers',
                        type : 'item',
                        icon : 'send',
                        url  : '/notify-user/customer',
                        exactMatch: true
                    },
                    {
                        id   : 'pushGuestt',
                        title: 'Notify Guest ',
                        type : 'item',
                        icon : 'send',
                        url  : '/notify-user/push-guest',
                        exactMatch: true
                    },
                    {
                        id   : 'notifyDriver',
                        title: 'Notify Drivers',
                        type : 'item',
                        icon : 'send',
                        url  : '/notify-user/driver',
                        exactMatch: true
                    },

                    {
                        id   : 'notifyPdasuser',
                        title: 'Notify PDAS ',
                        type : 'item',
                        icon : 'send',
                        url  : '/notify-user/pdas-user',
                        exactMatch: true
                    },

                    {
                        id   : 'scheduleLogs',
                        title: 'Schedule logs',
                        type : 'item',
                        icon : 'schedule',
                        url  : '/notify-user/schedule-logs',
                        exactMatch: true
                    },

                    // {
                    //     id   : 'recurringLogs',
                    //     title: 'Recurring logs',
                    //     type : 'item',
                    //     icon : 'schedule',
                    //     url  : '/notify-user/recurring-logs',
                    //     exactMatch: true
                    // },

                ]
            }, 

            {
                id       : 'category',
                title    : 'Manage Categories',
                type     : 'collapsable',
                icon     : 'category',
                children : [
                    {
                        id   : 'category',
                        title: 'Categories',
                        type : 'item',
                        icon : 'category',
                        url  : '/category/all',
                        exactMatch: true
                    },
                    {
                        id   : 'subCategories',
                        title: 'Sub Categories',
                        type : 'item',
                        icon : 'category',
                        url  : '/category/subcategories',
                        exactMatch: true
                    },
                ]
            },

            // {
            //     id       : 'category',
            //     title    : 'Categories',
            //     type     : 'item',
            //     icon     : 'category',
            //     url      : '/category/all',
            //     exactMatch: true,
            //
            // },
            {
                id       : 'commissions',
                title    : 'Commissions',
                type     : 'collapsable',
                icon     : 'attach_money',
                children : [
                    {
                        id   : 'totalCommission',
                        title: 'Total Commissions',
                        type : 'item',
                        icon : 'attach_money',
                        url  : '/commissions/total',
                        exactMatch: true
                    },
                    {
                        id   : 'phelnuCash',
                        title: 'Received Commission',
                        type : 'item',
                        icon : 'attach_money',
                        url  : '/commissions/phelnu-cash',
                        exactMatch: true
                    },
                ]
                
            },
            {
                id       : 'country',
                title    : 'Countries',
                type     : 'item',
                icon     : 'outlined_flag',
                url      : '/country/all',
                exactMatch: true, 
            }, 

            {
                id       : 'phoneModule',
                title    : 'Phone Models & Pricing',
                type     : 'item',
                icon     : 'app_settings_alt',
                url      : '/devices-brands/all',
                exactMatch: true, 
            }, 

            {
                id       : 'commissions',
                title    : 'Configurations',
                type     : 'collapsable',
                icon     : 'settings',
                children : [ 
                    {
                        id       : 'deliveryConfig',
                        title    : 'Delivery Configuration',
                        type     : 'item',
                        icon     : '',
                        url      : '/delivery-config',
                        exactMatch: true,
                    },
        
                    {
                        id       : 'bulkDeliveryConfig',
                        title    : 'Bulk Delivery Configuration',
                        type     : 'item',
                        icon     : '',
                        url      : '/bulk-delivery/config',
                        exactMatch: true,
                    },

                    {
                        id       : 'interRegionalConfig',
                        title    : 'Inter Regional Configuration',
                        type     : 'item',
                        icon     : '',
                        url      : '/inter-regional/config',
                        exactMatch: true, 
                    },
                    
                    {
                        id       : 'businessDeliveryConfig',
                        title    : 'Business Partner Order Config...',
                        type     : 'item',
                        icon     : '',
                        url      : '/business-delivery/config',
                        exactMatch: true,
                    },
                ]
            },
            // {
            //     id       : 'deliveryConfig',
            //     title    : 'Delivery Configuration',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/delivery-config',
            //     exactMatch: true,
            // },

            // {
            //     id       : 'bulkDeliveryConfig',
            //     title    : 'Bulk Delivery Configuration',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/bulk-delivery/config',
            //     exactMatch: true,
            // },
            // {
            //     id       : 'interRegionalConfig',
            //     title    : 'Inter Regional Configuration',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/inter-regional/config',
            //     exactMatch: true, 
            // },
            
            // {
            //     id       : 'businessDeliveryConfig',
            //     title    : 'Business Partner Order Config...',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/business-delivery/config',
            //     exactMatch: true,
            // },

            {
                id       : 'affiliatesTiersContent',
                title    : 'Affiliate Tiers & Content',
                type     : 'collapsable',
                icon     : 'vertical_split',
                children : [
                    {
                        id   : 'affiliatesTiers',
                        title: 'Tiers',
                        type : 'item',
                        icon : 'playlist_add',
                        url  : '/affiliates-tiers/tiers-update',
                        exactMatch: true
                    },
                    {
                        id   : 'affiliatesContent',
                        title: 'Content Manager',
                        type : 'item',
                        icon : 'web_stories',
                        url  : '/affiliates-tiers/content',
                        exactMatch: true
                    },
                ] 
            },

            {
                id       : 'eventTicketRequest',
                title    : 'Event ticket requests',
                type     : 'item',
                icon     : 'insert_drive_file',
                url      : '/event-ticket-requests',
                exactMatch: true,
            },

            {
                id       : 'homeContent',
                title    : 'Home content',
                type     : 'item',
                icon     : 'attach_money',
                url      : '/home-content',
                exactMatch: true,
            },

            // {
            //     id       : 'manageMenu',
            //     title    : 'Manage menu',
            //     type     : 'item',
            //     icon     : 'list',
            //     url      : '/menu',
            //     exactMatch: true,
            // },

            {
                id       : 'rechargeList',
                title    : 'Recharge List',
                type     : 'item',
                icon     : 'category',
                url      : '/recharge-list',
                exactMatch: true,
            },

            {
                id       : 'abuseUserList',
                title    : 'Risky users',
                type     : 'item',
                icon     : 'people_outline',
                url      : '/risky-users',
                exactMatch: true,
            },

            {
                id       : 'emailLogs',
                title    : 'Email Logs',
                type     : 'item',
                icon     : 'email',
                url      : '/email-logs',
                exactMatch: true,
            },


            {
                id       : 'managePromos',
                title    : 'Manage Promos',
                type     : 'item',
                icon     : 'local_play',
                url      : '/manage-promos',
                exactMatch: true,
            },
            






            // {
            //     id       : 'booster',
            //     title    : 'Booster',
            //     type     : 'item',
            //     icon     : 'flash_on',
            //     url      : '/booster',
            //     exactMatch: true,
                
            // },


            {
                id       : 'upgrade',
                title    : 'Version Upgrade',
                type     : 'item',
                icon     : 'update',
                url      : '/upgrade-version',
                exactMatch: true,
                
            },
            {
                id       : 'feedback',
                title    : 'Feedback',
                type     : 'collapsable',
                icon     : 'feedback',
                exactMatch: true,

                children : [
                    {
                        id   : 'feedbacks',
                        title: 'PC Feedbacks',
                        type : 'item',
                        icon : 'feedback',
                        url  : '/feedback/all',
                        exactMatch: true
                    },
                    {
                        id   : 'driversFeedbacks',
                        title: 'Drivers Feedbacks',
                        type : 'item',
                        icon : 'feedback',
                        url  : '/feedback/drivers',
                        exactMatch: true
                    },
                    {
                        id   : 'PDASFeedbacks',
                        title: 'PDAS Feedbacks',
                        type : 'item',
                        icon : 'feedback',
                        url  : '/feedback/pdas',
                        exactMatch: true
                    },
                ]
                
            },
            {
                id       : 'vehicleInspection',
                title    : 'Vehicle Inspection',
                type     : 'item',
                icon     : 'commute',
                url      : '/vehicle-inspection/all',
                exactMatch: true,
            },
            
            



            // {
            //     id       : 'affiliate_and_referral_earning',
            //     title    : 'Feedback',
            //     type     : 'item',
            //     icon     : 'feedback',
            //     url      : '/feedback/all',
            //     exactMatch: true,
            // },





            
            // {
            //     id       : 'deal',
            //     title    : 'Deals',
            //     type     : 'item',
            //     icon     : 'local_offer',
            //     url      : '/deals',
            //     exactMatch: true,

                
            // },
            // {
            //     id       : 'event',
            //     title    : 'Events',
            //     type     : 'item',
            //     icon     : 'event',
            //     url      : '/events',
            //     exactMatch: true,

                
            // },

            // {
            //     id       : 'order',
            //     title    : 'Orders',
            //     type     : 'item',
            //     icon     : 'assignment_returned',
            //     url      : '/merchant/orders',
            //     exactMatch: true,

                
            // },
            // {
            //     id       : 'transaction',
            //     title    : 'Transactions',
            //     type     : 'item',
            //     icon     : 'money',
            //     url      : '/transactions',
            //     exactMatch: true,

                
            // },

            // {
            //     id       : 'inbox',
            //     title    : 'Inbox',
            //     type     : 'item',
            //     icon     : 'inbox',
            //     url      : '/inbox',
            //     exactMatch: true,

                
            // },
            // {
            //     id       : 'setting',
            //     title    : 'Settings',
            //     type     : 'item',
            //     icon     : 'settings',
            //     url      : '/settings',
            //     exactMatch: true,

                
            // },
            // {
            //     id       : 'feedback',
            //     title    : 'Feedback',
            //     type     : 'item',
            //     icon     : 'feedback',
            //     url      : '/feedback',
            //     exactMatch: true,
                
            // },




        ]
    },
    




];
