import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { CommonService } from "@fuse/services/common.service";
import {
    accessDeniedLabel,
    countriesFilters,
    decrypt,
    permissionType,
    shift,
} from "@fuse/utils/enums";
import { AuthService } from "app/main/pages/auth.service";

@Component({
    selector: "app-affiliate-guidelines",
    templateUrl: "./affiliate-guidelines.component.html",
    styleUrls: ["./affiliate-guidelines.component.scss"],
})
export class AffiliateGuidelinesComponent implements OnInit {
    editMode: boolean = false;
    contentForm: FormGroup;
    actionPermission = "";
    public permissionType = permissionType;
    accessDeniedLabel = accessDeniedLabel;

    labelColorInfo = "#fff";
    enableFieldColorInfo = "#fff";

    quillConfig = {
        //toolbar: '.toolbar',
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],

                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction

                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],

                ["clean"], // remove formatting button

                ["link"],
            ],
        },
    };


    countries = countriesFilters.filter((item) => item.id !== 3);
    // selectedCountry = '1';

    constructor(
        private _fb: FormBuilder,
        private _AuthService: AuthService,
        private _CommonService: CommonService,
        private _progressBar: FuseProgressBarService,
        private _matSnackBar: MatSnackBar
    ) {
        this._AuthService.actionPermissionUpdate.subscribe((res) => {
            if (res) {
                this.actionPermission = res;
            } else {
                if (
                    decrypt(localStorage.getItem("sdffhvv"), shift) ==
                    "Read/Write"
                ) {
                    this.actionPermission = "Read/Write";
                } else {
                    this.actionPermission = null;
                }
            }
        });
    }

    ngOnInit(): void {
        // console.log("createform--==>", this.data);
        // if(this.data){
        this.createForm();
        this.contentForm.get('country').setValue('1')
        // }else{
        this.getContentData();

        // }
    }

    quillContentChanged(event) {
        // console.log(this.editor);
        console.log(event);
    }

    createForm() {
        this.contentForm = this._fb.group({
            country : ['', Validators.required],
            title: new FormControl({ value: ''}, [
                Validators.required,
            ]),
            content: new FormControl({ value:'', disabled: true }, [
                Validators.required,
            ]),
        });
    }

    onEnableForm() {
        this.contentForm.enable();
        this.contentForm.get("title").enable();
        this.contentForm.get("content").enable();
        this.editMode = true;
    }

    onDisableForm() {
        // this.contentForm.disable();
        this.contentForm.get("title").disable();
        this.contentForm.get("content").disable();
        this.editMode = false;
    }

    onSaveContent() {
        
        const payload = {
            title: this.contentForm.value.title,
            content: this.contentForm.value.content,
            country : Number(this.contentForm.value.country),
            type: 2,
        };

        this._progressBar.show();
        this._CommonService
            .updateAffiliateTermsandGuildeline(payload)
            .subscribe((res: any) => {
                this._progressBar.hide();
                if (res.response == 1) {
                    this.onDisableForm();
                    this._matSnackBar.open(res.message, "OKAY", {
                        duration: 3000,
                    });
                } else {
                    this._matSnackBar.open(res.message, "OKAY", {
                        duration: 3000,
                    });
                }
            });
    }

    onCountryChange(){
        this.getContentData()
    }

    getContentData() {
        this._progressBar.show();
        this._CommonService.getAffiliateTermsandGuildeline(2, Number(this.contentForm.get('country').value)).subscribe((res: any) => {
                this._progressBar.hide();
                if (res.response == 1) {
                    
                    if(res?.data?.title)
                    this.contentForm.get('title').setValue(res.data.title);

                    if(res?.data?.content)
                    this.contentForm.get('content').setValue(res.data.content);
                    
                } else {

                    this._matSnackBar.open(res.message, "OKAY", {
                        duration: 3000,
                    });

                }
            });
    }
}
