<div class="dialog-content-wrapper">
    <div class="dialog-header">
        <span class="dialog-title animate-fade-in-up">{{this.isAuthSetUp == 0 ? 'Connect your authenticator app' : 'Submit PIN'}}</span>
        <button mat-icon-button (click)="matDialogRef.close(false)">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content class="pt-12">
        <p class="dialog-instructions animate-fade-in-up" *ngIf="this.isAuthSetUp == 0">
            Use your <b>authenticator app (Google Authenticator, Duo Mobile, or others)</b> to scan this QR code.
        </p>

        <p class="dialog-instructions animate-fade-in-up" *ngIf="this.isAuthSetUp == 1">
            Open the <b>authenticator app</b> on your device to view your authentication code and verify your identity.
        </p>


        <div class="qr-code-container animate-fade-in-up" *ngIf="this.isAuthSetUp == 0">
            <img [src]="this.qrImage" alt="QR Code" class="qr-code" />
        </div>

        <div *ngIf="this.isAuthSetUp == 1" class="animate-fade-in-up mt-24 mb-24">

            <!-- <mat-form-field appearance="outline" color="accent" class="w-100-p"> -->
                <!-- <mat-label style="color: whitesmoke;">Enter PIN *</mat-label> -->
                <!-- <input matInput placeholder="Enter your PIN" [formControlName]="code" maxlength="6" type="number"
                    required aria-label="Enter PIN" maxlength="6" /> -->
                <span style="font-size: 16px; color: #ddd;">Enter PIN *</span>
                <div style="margin-top: 15px;margin-bottom: 5px;">
                    <app-otp-form [isFieldReset]="this.isFieldReset"  #otpForm></app-otp-form>
                </div>
                <span style="font-size: 12px; color: #ccc; margin-top: 10px; display: block; font-weight: 600;">6-digit PIN</span>
                <!-- <mat-hint align="end" style="color: #ddd;">6-digit code</mat-hint> -->
            <!-- </mat-form-field> -->

        </div>

        <ng-container *ngIf="this.isAuthSetUp == 0">
            <a class="dialog-link animate-fade-in-up"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN"
                target="_blank">Don’t have an authenticator app?</a>
        </ng-container>
    </div>

    <mat-dialog-actions class="pt-24 pb-20" align="end">
        <button mat-raised-button color="accent" class="save-button"  [disabled]="this.isAuthSetUp == 1 ? !isOtpFormValid : null" (click)="onNext()"
            aria-label="Continue to the next step">
            {{this.isAuthSetUp == 0 ? 'Continue' : 'Submit'}}
        </button>
    </mat-dialog-actions>
</div>