<div class="contentBody">  
    <div class="titleHeader" style="margin-top: 15px; margin-bottom: 30px;">
        <h4 class="">Toolkit Resources</h4>
      
        <div class="d-flex">
            <!-- <button [disabled]="!((this.staticBannerURL || this.mobileFriendlyBannersURL))" mat-flat-button (click)="onSaveBanner()">Save</button> -->
            <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                <button mat-flat-button (click)="onSaveBanner()">Save</button>
            </ng-container>
        </div> 
    </div>
    <div class="contentWrapper">
        <div>

            <h3>Static Banners:</h3>
            <div *ngIf="staticBannerURL" class="image-wrapper" style="width: 100%; aspect-ratio: 5/3; position: relative;" fxlayout="row" fxLayoutAlign="center center">
                <img class="media cursor-pointer" style="width: 100%;height: 100%; object-fit: contain;" [src]="staticBannerURL" (click)="onViewImage('')">
                <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                    <mat-icon (click)="onRemoveDoc('static')" style="position: absolute; top: 5px; right: 30px; cursor: pointer;" *ngIf="this.isStaticBannerEditMode" color="accent">delete_outline</mat-icon>
                    <mat-icon (click)="staticBannerURL ? fileInputFront.click() : null" style="position: absolute; top: 5px; right: 0; cursor: pointer;" *ngIf="this.isStaticBannerEditMode" color="accent">edit</mat-icon>
                    <!-- <span style="position: absolute; border-radius: 2px; color: #222; display: block; padding: 1px 5px; font-weight: 600; background-color: #ddd; left: 0; bottom: 0;">Banner</span> -->
                    <input #fileInputFront style="visibility: hidden;" type="file" accept="image/*" (change)="addMediaDriverDoc($event, 'static')" style="display:none;"/>
                </ng-container>
            </div> 

            <ng-container *ngIf="!staticBannerURL">
                <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                    <input #fileInputFront style="visibility: hidden;" type="file" accept="image/*"
                        (change)="addMediaDriverDoc($event, 'static')" style="display:none;"/>
                    <div style="display: flex;flex-direction: column; justify-content: center; gap: 5px;width: 100%;aspect-ratio: 5/3;">
                        <mat-icon (click)="isStaticBannerEditMode ? fileInputFront.click() : null" color="accent"
                            [ngStyle]="{cursor: isStaticBannerEditMode ? 'pointer' : ''}"
                            style="height: 100%; width: 100%; border: 1px solid #222; border-radius: 6px; display: flex; align-items: center; justify-content: center;">add_circle_outline
                        </mat-icon>
                        <!-- <span class="text-center">Banner (300x250, 728x90, 160x600)</span> -->
                    </div>
                </ng-container>
            </ng-container>
        </div>


        <div>
            <h3>Mobile-Friendly Banners:</h3>
            <!-- <div *ngIf="mobileFriendlyBannersURL" class="product-image m-12" style="position: relative;" fxlayout="row" fxLayoutAlign="center center">
                <img class="media cursor-pointer" [src]="mobileFriendlyBannersURL" (click)="onViewImage('')">
                <mat-icon (click)="isEditModeForDriverDoc ? onRemoveDoc(docs, 'Front') : null" style="position: absolute; top: 5px; right: 30px; cursor: pointer;" *ngIf="this.isMobileFriendlyBannersEditMode" color="accent">delete_outline</mat-icon>
                <mat-icon (click)="isEditModeForDriverDoc ? fileInputFront.click() : null" style="position: absolute; top: 5px; right: 0; cursor: pointer;" *ngIf="this.isMobileFriendlyBannersEditMode" color="accent">edit</mat-icon>
                <span style="position: absolute; border-radius: 2px; color: #222; display: block; padding: 1px 5px; font-weight: 600; background-color: #ddd; left: 0; bottom: 0;">Banner</span>
                <input #fileInputFront style="visibility: hidden;" type="file" accept="image/*" (change)="addMediaDriverDoc($event)" style="display:none;"/>
            </div> -->
            <div *ngIf="mobileFriendlyBannersURL" class="image-wrapper" style="width: 100%; aspect-ratio: 5/3; position: relative;" fxlayout="row" fxLayoutAlign="center center">
                <img class="media cursor-pointer" style="width: 100%;height: 100%; object-fit: contain;" [src]="mobileFriendlyBannersURL" (click)="onViewImage('')">
                <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                    <mat-icon (click)="onRemoveDoc('mobile')" style="position: absolute; top: 5px; right: 30px; cursor: pointer;" *ngIf="this.isMobileFriendlyBannersEditMode" color="accent">delete_outline</mat-icon>
                    <mat-icon (click)="mobileFriendlyBannersURL ? fileInputFront.click() : null" style="position: absolute; top: 5px; right: 0; cursor: pointer;" *ngIf="this.isMobileFriendlyBannersEditMode" color="accent">edit</mat-icon>
                    <!-- <span style="position: absolute; border-radius: 2px; color: #222; display: block; padding: 1px 5px; font-weight: 600; background-color: #ddd; left: 0; bottom: 0;">Banner</span> -->
                    <input #fileInputFront style="visibility: hidden;" type="file" accept="image/*" (change)="addMediaDriverDoc($event, 'mobile')" style="display:none;"/>
                </ng-container>
            </div>  

            <ng-container *ngIf="!mobileFriendlyBannersURL">
                <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                    <input #fileInputFront style="visibility: hidden;" type="file" accept="image/*"
                        (change)="addMediaDriverDoc($event, 'mobile')" style="display:none;"/>
                    <div style="display: flex;flex-direction: column; justify-content: center; gap: 5px;width: 100%;aspect-ratio: 5/3;">
                        <mat-icon (click)="isMobileFriendlyBannersEditMode ? fileInputFront.click() : null" color="accent"
                            [ngStyle]="{cursor: isMobileFriendlyBannersEditMode ? 'pointer' : ''}"
                            style="height: 100%; width: 100%; border: 1px solid #222; border-radius: 6px; display: flex; align-items: center; justify-content: center;">add_circle_outline
                        </mat-icon>
                        <!-- <span class="text-center">Banner (320x50, 300x100)</span> -->
                    </div>
                </ng-container>
            </ng-container>
        </div>
        
    </div>
</div>