import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AddEditFaqDialogComponent } from '@fuse/components/add-edit-faq-dialog/add-edit-faq-dialog.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { accessDeniedLabel, decrypt, permissionType, shift } from '@fuse/utils/enums';
import { AuthService } from 'app/main/pages/auth.service';

@Component({
  selector: 'app-affiliates-content-tabs',
  templateUrl: './affiliates-content-tabs.component.html',
  styleUrls: ['./affiliates-content-tabs.component.scss','../../../../../node_modules/quill/dist/quill.core.css','../../../../../node_modules/quill/dist/quill.bubble.css', '../../../../../node_modules/quill/dist/quill.snow.css'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AffiliatesContentTabsComponent implements OnInit {

  selectedIndex = 0;
  coverPic: any = '../../../assets/images/backgrounds/default-cover.jpg';
  contentForm : FormGroup;
  labelColorInfo = '#fff';
  enableFieldColorInfo = '#fff';
  actionPermission = '';
  public permissionType = permissionType;
  accessDeniedLabel = accessDeniedLabel;


  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link']
      ]
    }
  }
  step: number;
  editMode: boolean = false;

  selectedTabIndex = 0; // Default tab index

  description : '';
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  faqData = []

  panelOpenState = false;
  isDataLoaded:boolean = false;

  constructor(
    private _fb : FormBuilder,
    private _matDialog: MatDialog,
    private _CommonService : CommonService,
    private _progressBar : FuseProgressBarService,
    private _AuthService : AuthService,
    private _matSnackBar : MatSnackBar,
    private route: ActivatedRoute,
    private _router : Router,
  ) { 
    
    this._AuthService.actionPermissionUpdate.subscribe((res)=>{ 
      if(res){
          this.actionPermission = res;
      }else{
          if(decrypt(localStorage.getItem('sdffhvv'), shift) == 'Read/Write'){
              this.actionPermission = 'Read/Write';
          }else{
              this.actionPermission = null;
          }
      }
    })

  }

  ngOnInit(): void {

     // Get the tab index from query params if it exists
     this.route.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTabIndex = + params['tab']; // Convert string to number
      }
    });


    this.createForm();
    this.contentForm.disable();
    this.contentForm.get('title').disable();
    this.contentForm.get('content').disable();
    this.step = 0;
    this.getFaqList();
    this.getContentData()
  }

  createForm(data?){
    this.contentForm = this._fb.group({
      title : new FormControl({value : data?.heading, disabled: true},[Validators.required]),
      content : new FormControl({value : data?.content, disabled: true},[Validators.required])
    })
  }
  
  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    // console.log("tabChangeEvent ===>",tabChangeEvent.index);
    this.selectedIndex = tabChangeEvent.index;
    // this._router.navigate(['/affiliates-tiers/content'])
    //  this.selectedTabIndex = index;

    // Update the query parameter
    this._router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabChangeEvent.index },
      queryParamsHandling: 'merge', // Merge with existing query params
    });
  }

  quillContentChanged(event) {
    // console.log(this.editor);
    console.log(event);
  }

  onEnableForm(){
    this.contentForm.enable();
    this.contentForm.get('title').enable();
    this.contentForm.get('content').enable();
    this.editMode = true;
  }
 

  /**
   * Set step
   *
   * @param {number} index
   */
  setStep(index: number): void
  {
      this.step = index;
  }

  onAddNewFAQ(){
      const dialog = this._matDialog.open(AddEditFaqDialogComponent, {
          panelClass : 'common-dialog-wrapper',
          width : '450px',
          autoFocus : false,
          data : {
            faqData: null,
            heading : 'Add New FAQ',
            btnText : 'Save'
          }
      })

      dialog.afterClosed().subscribe((res)=>{
        if(res){
          const payload = {
            question : res.question,
            answer : res.answer
          }
          this._progressBar.show();
          this._CommonService.addAffiliateEarningFaq(payload).subscribe((res:any) => {
            if(res.response == 1){
              this._progressBar.hide();
              this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
              this.faqData = res.data;
            }else{
              this._progressBar.hide();
              this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
            }
          })
        }
      })
  }

  onUpdateFAQ(faq){
    const dialog = this._matDialog.open(AddEditFaqDialogComponent, {
        panelClass : 'common-dialog-wrapper',
        width : '450px',
        autoFocus : false,
        data : {
          faqData: faq,
          heading : 'Edit FAQ',
          btnText : 'Update'
        }
    })

    dialog.afterClosed().subscribe((res)=>{
      if(res){
        console.log("resupdate", res);
        this._CommonService.updateFAQ(res).subscribe((res:any)=>{
          if(res.response == 1){
            this._progressBar.hide();
            this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
            this.faqData = res.data;
          }else{
            this._progressBar.hide();
            this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
          }
        })
      }
    })
  }

  onDisableForm(){
    this.contentForm.disable();
    this.contentForm.get('title').disable();
    this.contentForm.get('content').disable();
    this.editMode = false;
  }
  

  onSaveContent(){
    console.log("this.fomr", this.contentForm.value);
    const payload = {
        heading : this.contentForm.value.title,
        content : this.contentForm.value.content,
        id: 1,
    }
    this._progressBar.show();
    this._CommonService.updateAffiliateContent(payload).subscribe((res:any)=>{
      this._progressBar.hide();
      if(res.response == 1){
        this.onDisableForm();
        this._matSnackBar.open(res.message, 'OKAY',{duration : 3000});
      }else{
        this._matSnackBar.open(res.message, 'OKAY',{duration : 3000});
      }
    })
  }

  getFaqList(){
    this._progressBar.show();
    this._CommonService.getAffiliateFAQs().subscribe((res:any)=>{
      console.log("res", res);
      this._progressBar.hide();
      this.isDataLoaded = true;
      if(res.response == 1){
        this.faqData = res.data;
      }else{
        this.faqData = []
        this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
      }
    })
  }

  getContentData(){
    this._progressBar.show();
    this._CommonService.getAffiliateContent().subscribe((res:any) => {
      this._progressBar.hide();
      this.isDataLoaded = true;
      if(res.response == 1){
        this.createForm(res.data.filter((item) => item.id == 1)[0])
      }else{ 
      }
    })
  }

  onDeleteFAQ(faq){ 
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    }); 
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete this faq?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if(result){
        this._progressBar.show();
        const payload = {id  : faq.id} 
        this._CommonService.deleteFAQ(payload).subscribe((res:any) => {
          this._progressBar.hide();
          if(res.response == 1){
            this.faqData = res.data;
            this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
          }else{
            this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
          }
        })
      }
    })  
  }

}