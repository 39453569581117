import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { EventEmitter } from "protractor";

@Component({
    selector: "app-otp-form",
    templateUrl: "./otp-form.component.html",
    styleUrls: ["./otp-form.component.scss"],
})
export class OtpFormComponent implements OnInit,  AfterViewChecked {
    otp: string[] = Array(6).fill(""); // Array to hold OTP digits
    // @Output() otpValue = new EventEmitter();

    formInput = ["input1", "input2", "input3", "input4", "input5", "input6"];
    // @ViewChildren("formRow") rows: any;
    // form: FormGroup;
    form!: FormGroup;

    @Input() isFieldReset: boolean = false;

    @ViewChildren("formRow") rows!: QueryList<any>;

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group(
            this.formInput.reduce((acc, input) => {
                acc[input] = [
                    "",
                    [Validators.required, Validators.pattern(/^\d$/)],
                ];
                return acc;
            }, {})
        );
    } 
     
    ngAfterViewChecked(): void {
        // if(this.isFieldReset){
        //     this.form.reset();
        // }
    }
     
    ngOnInit(): void {}

    toFormGroup(elements) {
        const group: any = {};
        elements.forEach((key) => {
            group[key] = new FormControl("", Validators.required);
        });
        return new FormGroup(group);
    }

    keyDownEvent(event: KeyboardEvent, index: number, input: string) {
        const currentInput = this.rows.toArray()[index].nativeElement;

        if (event.key === "Tab" && !event.shiftKey) {
            // Move to the next input
            event.preventDefault();
            const nextIndex = index + 1;
            if (nextIndex < this.formInput.length) {
                this.rows.toArray()[nextIndex].nativeElement.focus();
            }
        } else if (event.key === "Tab" && event.shiftKey) {
            // Move to the previous input
            event.preventDefault();
            const prevIndex = index - 1;
            if (prevIndex >= 0) {
                this.rows.toArray()[prevIndex].nativeElement.focus();
            }
        } else if (event.key === "Backspace") {
            // Clear current input and move to the previous input
            event.preventDefault();
            if (currentInput.value) {
                this.form.get(input)?.setValue("");
            } else if (index > 0) {
                const prevInput = this.rows.toArray()[index - 1].nativeElement;
                prevInput.focus();
                this.form.get(this.formInput[index - 1])?.setValue("");
            }
        }
    }

    onInput(event: Event, index: number) {
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement.value;

        if (/^\d$/.test(value)) {
            this.form.get(this.formInput[index])?.setValue(value); // Set value for the current input
            // Move to the next field only if the current input is valid
            const nextIndex = index + 1;
            if (nextIndex < this.formInput.length) {
                this.rows.toArray()[nextIndex].nativeElement.focus();
            }
        } else {
            // Reset value if invalid
            inputElement.value = "";
        }
    }

    onPaste(event: ClipboardEvent) {
        const pastedData = event.clipboardData?.getData("text") || "";
        const otpLength = this.otp.length;
        if (pastedData.length === otpLength) {
            for (let i = 0; i < otpLength; i++) {
                if (/^\d$/.test(pastedData[i])) {
                    this.form.get(this.formInput[i])?.setValue(pastedData[i]);
                }
            }
            const lastInput = this.rows.toArray()[otpLength - 1]?.nativeElement;
            lastInput?.focus();
        }
        event.preventDefault();
    }

    get isFormValid(): boolean {
        return this.form.valid;
    }

    onSubmit() {
        console.log(this.form.value);
    }

    emitOtpValue() {
        const otp = this.formInput
            .map((input) => this.form.get(input)?.value)
            .join("");
        // this.otpValue.emit(otp);
    }

}
