import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private _httpClient:HttpClient) { }


  addToWallet(body){

    var reqHeaders = new HttpHeaders({
        'Content-Type' : 'application/json',
        'x-access-token': localStorage.getItem('userToken')
    });
    return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'api/v12/admin/AddRemoveDriverFromWaiting' , body, {headers:reqHeaders})
            .subscribe((response: any) => {
            if(response.response)
                resolve(response);
            }, reject);
    });
  }


  addConvenienceFee(body){
    var reqHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/addRemoveOfflinePdas' , body, {headers:reqHeaders})
  }

  uploadDocs(body){
    const formData = new FormData();
    formData.append("image", body)
    console.log("this is the body", formData);
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/upload_docs',formData, {headers:reqHeaders})
  }

  uploadDriverMainDoc(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.put(environment.apiURL + 'api/v12/admin/update-driver-doc',body, {headers:reqHeaders})
  }

  // update phone number 
  updatePhoneNumber(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.put(environment.apiURL + 'api/v12/admin/update-user-phone',body, {headers:reqHeaders})
  }

  skipWarningBanner(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/skipWarningBanner',body, {headers:reqHeaders})
  }

  updateDriverProfileInfo(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/updateDriverProfile',body, {headers:reqHeaders})
  }

  updateOrderDropStatus(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/update-order-drop-status',body, {headers:reqHeaders})
  }
  // v12/admin/update-bulk-order-status

  updateDriverAddress(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/update-driver-address',body, {headers:reqHeaders})
  }

  getTiersData(){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.get(environment.apiURL + 'api/v12/admin/affiliate-earning-tiers',{headers:reqHeaders})
  }

  updateTiersPlans(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.put(environment.apiURL + 'api/v12/admin/update-affiliate-earning-tiers',body, {headers:reqHeaders})
  }

  addAffiliateEarningFaq(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/affiliate-earning-faq',body, {headers:reqHeaders})
  }

  getAffiliateFAQs(){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.get(environment.apiURL + 'api/v12/admin/affiliate-earning-faq', {headers:reqHeaders})
  }

  updateFAQ(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.put(environment.apiURL + 'api/v12/admin/affiliate-earning-faq',body, {headers:reqHeaders})
  }

  deleteFAQ(body){
    // let reqHeaders = new HttpHeaders({
    //   'x-access-token': localStorage.getItem('userToken')
    // });
    const httpOptions = {
      headers: new HttpHeaders({
              'x-access-token': localStorage.getItem('userToken')
          }
      ),
      body : body};
    return this._httpClient.delete(environment.apiURL + 'api/v12/admin/affiliate-earning-faq', httpOptions)
  }

  getAffiliateContent(){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.get(environment.apiURL + 'api/v12/admin/get-affiliate-earning-content', {headers:reqHeaders})
  }
  

  updateAffiliateContent(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.put(environment.apiURL + 'api/v12/admin/update-affiliate-earning-content',body, {headers:reqHeaders})
  }


  updateAffiliateTermsandGuildeline(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.put(environment.apiURL + 'api/v12/admin/update-affiliate-content',body, {headers:reqHeaders})
  }

  
  getAffiliateTermsandGuildeline(type, country?){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/get-affiliate-content', {type : type, country: country || null}, {headers:reqHeaders})
  }

  

  getToolkitBanners(){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.get(environment.apiURL + 'api/v12/admin/affiliate-toolkit-banner', {headers:reqHeaders})
  }

  updateToolkitBanners(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/affiliate-toolkit-banner',body, {headers:reqHeaders})
  }


  // affiliate post templates
  addAffiliatePost(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/affiliate-post-template', body, {headers:reqHeaders})
  }

  getAffiliateToolkit(){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.get(environment.apiURL + 'api/v12/admin/affiliate-post-template', {headers:reqHeaders})
  }

  onDeleleTemplatePost(body){ 
    const httpOptions = {headers: new HttpHeaders({'x-access-token': localStorage.getItem('userToken')}), body : body};
    return this._httpClient.delete(environment.apiURL + 'api/v12/admin/affiliate-post-template', httpOptions)
  }

  onUpdateTemplatePost(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.put(environment.apiURL + 'api/v12/admin/affiliate-post-template',body, {headers:reqHeaders})
  }

  onMarkPharmacyPdas(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/mark-as-pharmacy-pdas',body, {headers:reqHeaders})
  }

  onMarkPharmacyDriver(body){
    let reqHeaders = new HttpHeaders({
      'x-access-token': localStorage.getItem('userToken')
    });
    return this._httpClient.post(environment.apiURL + 'api/v12/admin/mark-as-pharmacy-dedicated-driver',body, {headers:reqHeaders})
  }

  

}
