<div class="titleHeader" style="margin-top: 15px; margin-bottom: 30px;">
    <h4 class="">Affiliate Guidelines</h4>
    <div class="d-flex align-items-center" style="gap:8px;">
        <ng-container *ngIf="this.actionPermission == this.permissionType.action">
            <button mat-icon-button *ngIf="!this.editMode" (click)="onEnableForm()"><span
                    class="material-icons">edit</span></button>
        </ng-container> 
    </div>
</div>
<form [formGroup]="this.contentForm">
    <div class="contentBody">
        <!-- <mat-form-field appearance="outline" class="w-200 countrySp">
            <mat-select [(ngModel)]="selectedCountry" placeholder="">
                <mat-option *ngFor="let item of countries" [value]="item.id">{{item.title}}</mat-option>
            </mat-select>
        </mat-form-field> -->

        <mat-form-field appearance="outline" floatLabel="always" class="pl-4 mb-12 countrySp">
            <mat-label [style.color]="labelColorInfo">Country</mat-label>
            <mat-select (selectionChange)="onCountryChange()" formControlName="country" placeholder="">
                <mat-option *ngFor="let item of countries" [value]="item.id.toString()">{{item.title}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="pl-4">
            <mat-label [style.color]="labelColorInfo">Heading</mat-label>
            <input style="caret-color: #ddd;" type="text" formControlName="title" matInput placeholder="Heading"
                [style.color]="enableFieldColorInfo" />
            <mat-error>Heading is required</mat-error>
        </mat-form-field>

        <div style="margin-top: 15px; min-height: 466px;">
            <quill-editor [modules]="quillConfig" (contentChanged)="quillContentChanged($event)"
                [disabled]="this.editMode" formControlName="content"></quill-editor>
            <span style="display: inline-block; color: #ff0000; margin-top: 4px;"
                *ngIf="this.contentForm.get('content').hasError('required') && this.contentForm.get('content').touched">Content
                is required</span>
        </div>

        <div class="d-flex" *ngIf="this.editMode" style="margin-top: 15px; margin-bottom: 30px;">
            <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                <button mat-flat-button (click)="onSaveContent()">Save</button>
            </ng-container>
        </div>

    </div>
</form>