<div class="dialog-content-wrapper">
    <div class="dialog-header">
        <span class="dialog-title">Advanced Search Options</span>
        <button mat-icon-button (click)="matDialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="commonFilterWapper">
                <ng-container *ngIf="this._data?.allowOptions?.country">
                    <div class="row">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label style="color: #039be5;"> Country </mat-label>
                            <mat-select formControlName="country">
                                <mat-option *ngFor="let item of countriesFilters" [value]="item.id">
                                    {{item.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="this._data.allowOptions?.status">
                    <div class="row">
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label style="color: #039be5;"> Stauts </mat-label>
                            <mat-select formControlName="status">
                                <mat-option *ngFor="let item of this._data.allowOptions.statusOption" [value]="item.id">
                                    {{item.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </form>
    </div>

    <mat-dialog-actions>
        <div class="commonFilterAction"> 
            <button mat-flat-button color="primary" class="reset-button" (click)="onResetAllFilter()" aria-label="SEARCH">
                Reset
            </button>
            <button mat-raised-button color="accent" class="save-button" (click)="onSave()" aria-label="SEARCH">
                Save
            </button>
        </div>
    </mat-dialog-actions> 
</div>