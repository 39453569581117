import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BulkDeliveryConfig } from '../bulk-delivery-config.model';
import { BulkDeliveryConfigService } from '../bulk-delivery-config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { accessDeniedLabel, decrypt, permissionType, shift } from '@fuse/utils/enums';
import { AuthService } from 'app/main/pages/auth.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
    selector: 'app-bulk-delivery-config',
    templateUrl: './bulk-delivery-config.component.html',
    styleUrls: ['./bulk-delivery-config.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class BulkDeliveryConfigComponent implements OnInit {
    
    isShowEditNg: boolean = true;
    isShowEditGh: boolean = true;
    isDisabled = true;
    upgradeForm: FormGroup;
    public disableFieldColorInfo: any = '#ffffff';
    DConfig: BulkDeliveryConfig;

    actionPermission = '';
    public permissionType = permissionType;
    accessDeniedLabel = accessDeniedLabel;
    isPhelnuCommisionGhEdit:boolean = true;
    isPhelnuCommisionNgEdit:boolean = true;

    phlenuCommision

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _formBuilder: FormBuilder,
        private _AuthService : AuthService,
        private _fuseProgressBar : FuseProgressBarService,
        private _BulkDeliveryConfigService: BulkDeliveryConfigService,
        private _matSnackBar: MatSnackBar,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._AuthService.actionPermissionUpdate.subscribe((res)=>{ 
            if(res){
                this.actionPermission = res;
            }else{
                if(decrypt(localStorage.getItem('sdffhvv'), shift) == 'Read/Write'){
                    this.actionPermission = 'Read/Write';
                }else{
                    this.actionPermission = null;
                }
            }
        })

    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void { 

        this.phlenuCommision = this._BulkDeliveryConfigService.phlenuCommision;

        // Subscribe to update product on changes
        this._BulkDeliveryConfigService.bulkDeliveryConfig.pipe(takeUntil(this._unsubscribeAll)).subscribe(DConfig => {
            console.log("DConfig", DConfig); 
            this.DConfig = new BulkDeliveryConfig(DConfig);
            this.upgradeForm = this.createUpgradeForm();
            // this.deliveryRadiusForm = this.createDeliveryRadiusForm()                   
        }); 
 
    }



    /**
    * create Upgrade form
    *
    * @returns {FormGroup}
    */
    createUpgradeForm(): FormGroup {

        const phelnuCommisionGh = this.phlenuCommision.filter((item => item.country == 'Ghana'))[0]
        const phelnuCommisionNg = this.phlenuCommision.filter((item => item.country != 'Ghana'))[0]


        console.log("DConfigform", this.DConfig);
        return this._formBuilder.group({
            basePriceGh: [{ value: this.DConfig.basePriceGh, disabled: this.isDisabled }, [Validators.min(0)]],
            additionalRateGh: [{ value: this.DConfig.additionalRateGh, disabled: this.isDisabled }, [Validators.min(0)]],
            distanceRateGh: [{ value: this.DConfig.distanceRateGh, disabled: this.isDisabled }],
            additionalItemGh: [{ value: this.DConfig.additionalItemGh, disabled: this.isDisabled }, [Validators.min(0)]],
            discountGh: [{ value: this.DConfig.discountGh, disabled: this.isDisabled }, [Validators.min(0)]],
            damageCoverPercentageGh : [{value : this.DConfig.damageCoverPercentageGh, disabled : this.isDisabled},  [Validators.min(0), Validators.max(100)]],
            conveniencePercentageGh : [{value : this.DConfig.conveniencePercentageGh, disabled : this.isDisabled},  [Validators.min(0), Validators.max(100)]],
            conveniencePercentageOfflineGh : [{value : this.DConfig.conveniencePercentageOfflineGh, disabled : this.isDisabled}, [Validators.min(0), Validators.max(100)]],
            // loadingFeePercentageGh : [{value : this.DConfig.loadingFeePercentageGh, disabled : this.isDisabled}, [Validators.min(0), Validators.max(100)]],
            // basePriceNg: [{ value: this.DConfig.basePriceNg, disabled: this.isDisabled }, [Validators.min(0)]],
            // additionalRateNg: [{ value: this.DConfig.additionalRateNg, disabled: this.isDisabled }, [Validators.min(0)]],
            // distanceRateNg: [{ value: this.DConfig.distanceRateNg, disabled: this.isDisabled }],
            // additionalItemNg: [{ value: this.DConfig.additionalItemNg, disabled: this.isDisabled }, [Validators.min(0)]],
            discountNg: [{ value: this.DConfig.discountNg, disabled: this.isDisabled }, [Validators.min(0)]],
            damageCoverPercentageNg : [{value : this.DConfig.damageCoverPercentageNg, disabled: this.isDisabled},  [Validators.min(0), Validators.max(100)]],
            conveniencePercentageNg : [{value : this.DConfig.conveniencePercentageNg, disabled : this.isDisabled},  [Validators.min(0), Validators.max(100)]],
            conveniencePercentageOfflineNg : [{value : this.DConfig.conveniencePercentageOfflineNg, disabled : this.isDisabled}, [Validators.min(0), Validators.max(100)]],
            // loadingFeePercentageNg : [{value : this.DConfig.loadingFeePercentageNg, disabled : this.isDisabled}, [Validators.min(0), Validators.max(100)]],
            phelnuCommisionGh : [{value : phelnuCommisionGh?.commission || '', disabled : this.isDisabled}, [Validators.min(0), Validators.max(100)]],
            phelnuCommisionNg : [{value : phelnuCommisionNg?.commission || '', disabled : this.isDisabled}, [Validators.min(0), Validators.max(100)]],

        });
    }

    edit(type) {
        if (type === "Ghana") {
            this.isShowEditGh = false;
            this.upgradeForm.controls['basePriceGh'].enable();
            this.upgradeForm.controls['additionalRateGh'].enable();
            this.upgradeForm.controls['distanceRateGh'].enable();
            this.upgradeForm.controls['additionalItemGh'].enable();
            this.upgradeForm.controls['discountGh'].enable();
            this.upgradeForm.controls['damageCoverPercentageGh'].enable();
            this.upgradeForm.controls['conveniencePercentageGh'].enable();
            this.upgradeForm.controls['conveniencePercentageOfflineGh'].enable();
            // this.upgradeForm.controls['loadingFeePercentageGh'].enable();
        } else if (type == "Nigeria") {
            this.isShowEditNg = false;
            // this.upgradeForm.controls['basePriceNg'].enable();
            // this.upgradeForm.controls['additionalRateNg'].enable();
            // this.upgradeForm.controls['distanceRateNg'].enable();
            // this.upgradeForm.controls['additionalItemNg'].enable();
            this.upgradeForm.controls['discountNg'].enable();
            this.upgradeForm.controls['damageCoverPercentageNg'].enable();
            this.upgradeForm.controls['conveniencePercentageNg'].enable();
            this.upgradeForm.controls['conveniencePercentageOfflineNg'].enable();
            // this.upgradeForm.controls['loadingFeePercentageNg'].enable();
        }else if(type == 'phelnuCommisionGh'){
            this.isPhelnuCommisionGhEdit = false;
            this.upgradeForm.controls['phelnuCommisionGh'].enable();
        }else if(type == 'phelnuCommisionNg'){
            this.isPhelnuCommisionNgEdit = false;
            this.upgradeForm.controls['phelnuCommisionNg'].enable();
        }
    }

    
    savePhlenuCommision(country){
        this._fuseProgressBar.show();
        const payload = { orderType : 2, commission : country == 'Ghana' ? this.upgradeForm.get('phelnuCommisionGh').value : this.upgradeForm.get('phelnuCommisionNg').value, country : country }
        this._BulkDeliveryConfigService.savePhlenuCommision(payload).then((res:any)=>{
            this._fuseProgressBar.hide();
            if(res.response == 1){
                this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
                if(country == 'Ghana'){
                    this.isPhelnuCommisionGhEdit = true;
                    this.upgradeForm.controls['phelnuCommisionGh'].disable();
                }else{
                    this.isPhelnuCommisionNgEdit = true;
                    this.upgradeForm.controls['phelnuCommisionNg'].disable();
                }
            }
        })
    }


    saveConfig(country) { 
        // const {basePriceGh, additionalRateGh, distanceRateGh, additionalItemGh, discountGh, basePriceNg, additionalRateNg, distanceRateNg, additionalItemNg, discountNg, damageCoverPercentageGh, conveniencePercentageGh, damageCoverPercentageNg, conveniencePercentageNg, conveniencePercentageOfflineGh, conveniencePercentageOfflineNg} = this.upgradeForm.getRawValue();
        const {basePriceGh, additionalRateGh, distanceRateGh, additionalItemGh, discountGh, discountNg, damageCoverPercentageGh, conveniencePercentageGh, damageCoverPercentageNg, conveniencePercentageNg, conveniencePercentageOfflineGh, conveniencePercentageOfflineNg, loadingFeePercentageGh, loadingFeePercentageNg} = this.upgradeForm.getRawValue();
        this.upgradeForm.markAsPending();
        this.upgradeForm.disable();
        let toSend;
        if (country == 'Ghana') {
            this.isShowEditGh = true;
            toSend = {
                bulkConfigId : 1,
                basePrice : basePriceGh,
                additionalRate : additionalRateGh,
                distanceRate : distanceRateGh,
                additionalItem : additionalItemGh,
                discount : discountGh,
                damageCoverPercentage : damageCoverPercentageGh,
                conveniencePercentage : conveniencePercentageGh,
                conveniencePercentageOffline : conveniencePercentageOfflineGh,
                // loadingFeePercentage : loadingFeePercentageGh
            }
        } else {
            this.isShowEditNg = true;
            toSend = {
                bulkConfigId : 2,
                // basePrice : basePriceNg,
                // additionalRate : additionalRateNg,
                // distanceRate : distanceRateNg,
                // additionalItem : additionalItemNg,
                discount : discountNg,
                damageCoverPercentage : damageCoverPercentageNg,
                conveniencePercentage : conveniencePercentageNg,
                conveniencePercentageOffline : conveniencePercentageOfflineNg,
                // loadingFeePercentage : loadingFeePercentageNg
            }
        }
        console.log("toSend", toSend);
        
        // Trigger the subscription with new data
        this._BulkDeliveryConfigService.updateBulkConfig(toSend).then((res: any) => {
            var msg: any;
            if (res.respone == 'Success') {
                msg = res.message;
            } else {
                this.upgradeForm.disable();
                msg = res.message;
            }

            // Show the success message
            this._matSnackBar.open(msg, 'OK', {
                verticalPosition: 'bottom',
                duration: 4000
            });
        });
    }

}
