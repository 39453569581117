<div class="affiliatesTiers page-layout simple tabbed primary-600">

    <!-- Fix the DOM sanitizing Stuff with Background IMAGE -->
    <div class="header p-10" [ngStyle]="{ background: 'url(' + coverPic + ') 0% 0% / auto 100%' }" fxLayout="column"
        fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end">
        <div class="info-section" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end">
            <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="center center">


                <!-- <span matBadge="✔" class="badge_min" matBadgeOverlap="true" matBadgeDescription="Verified"></span> -->

                <div class="name" fxLayout="column" fxLayoutAlign="center" [@animate]="{
                     value: '*',
                     params: { delay: '100ms', x: '-25px' }
                 }">
                    <div class="business_name">
                        <h1>Affiliates Content Manager</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- / HEADER -->


    <div class="contentTiers">
        <mat-tab-group dynamicHeight="true" [selectedIndex]="selectedTabIndex" (focusChange)="tabChanged($event)">
            <mat-tab label="How It Works">
                <div class="contentBody">
                    <div class="titleHeader" style="margin-top: 15px; margin-bottom: 30px;">
                        <h4 class="">How It Works Content</h4>
                        <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                            <button mat-icon-button *ngIf="!this.editMode" (click)="onEnableForm()"><span
                                    class="material-icons">edit</span></button>
                        </ng-container>
                    </div>

                    <form [formGroup]="this.contentForm">
                        <div class="contentBody">

                            <mat-form-field appearance="outline" floatLabel="always" class="pl-4">
                                <mat-label [style.color]="labelColorInfo">Heading</mat-label>
                                <input style="caret-color: #ddd;" type="text" [disabled]="true" formControlName="title"
                                    matInput placeholder="Heading" [style.color]="enableFieldColorInfo" />
                                <mat-error>Heading is required</mat-error>
                            </mat-form-field>

                            <div style="margin-top: 15px; min-height: 466px;">
                                <quill-editor [modules]="quillConfig" (contentChanged)="quillContentChanged($event)"
                                    formControlName="content"></quill-editor>
                                <span style="display: inline-block; color: #ff0000; margin-top: 4px;"
                                    *ngIf="this.contentForm.get('content').hasError('required') && this.contentForm.get('content').touched">Content
                                    is required</span>
                            </div>

                            <div class="d-flex" *ngIf="this.editMode" style="margin-top: 15px; margin-bottom: 30px;">
                                <button mat-flat-button (click)="onSaveContent()">Save</button>
                            </div>

                        </div>
                    </form>
                </div>
            </mat-tab>

            <mat-tab label="FAQ's Manage">
                <div class="contentBody">
                    <div class="titleHeader" style="margin-top: 15px; margin-bottom: 30px;">
                        <h4 class="">FAQ's</h4>
                        <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                            <button mat-icon-button (click)="onAddNewFAQ()"><span
                                    class="material-icons">add</span></button>
                        </ng-container>
                    </div>

                    <mat-accordion class="faqs-accordion">
                        <mat-expansion-panel *ngFor="let faq of faqData; let i = index" [expanded]="step === i"
                            (opened)="setStep(i)">
                            <mat-expansion-panel-header>
                                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="mr-12 s-20 secondary-text">help</mat-icon>
                                    {{faq.question}}
                                </mat-panel-title>
                                <mat-panel-description style="display: flex; justify-content: end;">
                                    <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                                        <button mat-icon-button (click)="onDeleteFAQ(faq);$event.stopPropagation();">
                                            <mat-icon class="mat-18" [style.color]="'#fff'">delete</mat-icon>
                                        </button>
                                        <button mat-icon-button (click)="onUpdateFAQ(faq);$event.stopPropagation();">
                                            <mat-icon class="mat-18" [style.color]="'#fff'">edit</mat-icon>
                                        </button>
                                    </ng-container>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            {{faq.answer}}
                        </mat-expansion-panel>
                    </mat-accordion>

                    <div *ngIf="!faqData?.length" class="mt-12">
                        <p class="text-center" style="color : #ddd">No questions found.
                            <ng-container *ngIf="this.actionPermission == this.permissionType.action">
                                <span (click)="onAddNewFAQ()" style="color : #0171b9; cursor: pointer;">Add
                                    question</span>
                            </ng-container>
                        </p>
                    </div>

                </div>
            </mat-tab>

            <mat-tab label="Affiliate Toolkit">
                <app-affiliate-toolkit></app-affiliate-toolkit>
            </mat-tab>

            <mat-tab label="Social Media Graphics">
                <div class="contentBody">
                    <router-outlet></router-outlet>
                </div>
            </mat-tab>

            <mat-tab label="Announcement">
                <div class="contentBody">
                    <app-announcement-config></app-announcement-config>
                </div>
            </mat-tab>

            <mat-tab label="Terms and Conditions">
                <div class="contentBody">
                    <app-affiliate-terms-and-conditions></app-affiliate-terms-and-conditions>
                </div>
            </mat-tab>

            <mat-tab label="Affiliate  Guidelines">
                <div class="contentBody">
                    <app-affiliate-guidelines></app-affiliate-guidelines>
                </div>
            </mat-tab>

        </mat-tab-group>
    </div>

</div>