import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommonCropperComponent } from "@fuse/components/common-cropper/common-cropper.component";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { SanCropperDialogComponent } from "@fuse/components/cropper-dialog/cropper-dialog.component";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { CommonService } from "@fuse/services/common.service";
import { accessDeniedLabel, decrypt, permissionType, shift } from "@fuse/utils/enums";
import { CustomizerService } from "app/main/global_services/customizer.service";
import { AuthService } from "app/main/pages/auth.service";
import { ThemeService } from "ng2-charts";
import { base64ToFile } from "ngx-image-cropper";

@Component({
    selector: "app-affiliate-toolkit",
    templateUrl: "./affiliate-toolkit.component.html",
    styleUrls: ["./affiliate-toolkit.component.scss"],
})
export class AffiliateToolkitComponent implements OnInit {

    bannerURLData:any;
    staticBannerURL: string = "";
    isStaticBannerEditMode: boolean = true;
    cropperDialogRef: MatDialogRef<CommonCropperComponent>;

    mobileFriendlyBannersURL: string = "";
    isMobileFriendlyBannersEditMode: boolean = true;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    actionPermission = '';
    public permissionType = permissionType;
    accessDeniedLabel = accessDeniedLabel;


    constructor(
        private _matProgressBar: FuseProgressBarService,
        private _CommonService: CommonService,
        private _AuthService : AuthService,
        private _customizerService: CustomizerService,
        private _matDialog: MatDialog,
        private _matSnakeBar: MatSnackBar
    ) {
        this._AuthService.actionPermissionUpdate.subscribe((res)=>{ 
            if(res){
                this.actionPermission = res;
            }else{
                if(decrypt(localStorage.getItem('sdffhvv'), shift) == 'Read/Write'){
                    this.actionPermission = 'Read/Write';
                }else{
                    this.actionPermission = null;
                }
            }
        })

    }

    ngOnInit(): void {
        this.getBannersURLs()
    }

    onViewImage(url) {}

    addMediaDriverDoc(event, isFor) {
        let files = event.target.files[0];
        let reader = new FileReader();

        // if(mediaType == 'pic'){

        reader.onload = (e: any) => {
            var image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
                // if(image.width < 640 && image.height > 120){
                //     this.showImageAlert();
                // } else {
                this.uploadImageDriverDoc(event.target.files[0], isFor);
                // this.showCropper(event);
                // }
            };
        };
        reader.readAsDataURL(files);
        // }
    }

    onRemoveDoc(isFor) {
        this.confirmDialogRef = this._matDialog.open(
            FuseConfirmDialogComponent,
            {
                disableClose: false,
            }
        );

        this.confirmDialogRef.componentInstance.confirmMessage =
            "Are you sure you want to delete this banner?";
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (isFor == "static") {
                    this.staticBannerURL = "";
                } else {
                    this.mobileFriendlyBannersURL = "";
                }
            }
        });
    }

    uploadImageDriverDoc(imageObj, isFor) {
        this._matProgressBar.show();
        this._CommonService.uploadDocs(imageObj).subscribe((res: any) => {
            if (res.response == 1) {
                this._matProgressBar.hide();
                if (isFor == "static") {
                    this.staticBannerURL = res?.link || "";
                } else {
                    this.mobileFriendlyBannersURL = res?.link || "";
                }
                this._matSnakeBar.open(res.message, "OKAY", { duration: 2000 });
            } else {
                this._matSnakeBar.open(
                    "Oops! It seems like the image hasn't been updated yet. Please try again.",
                    "OKAY",
                    { duration: 2000 }
                );
                this._matProgressBar.hide();
            }
        });
    }

    onSaveBanner() {
        const payload = {
            staticBannerUrl : this.staticBannerURL || '',
            mobieFriendlyBannerUrl : this.mobileFriendlyBannersURL || ''
        }
        this._matProgressBar.show();
        this._CommonService.updateToolkitBanners(payload).subscribe((res:any)=> {
            this._matProgressBar.hide();
            this._matSnakeBar.open(res.message, 'OKAY', {duration : 3000});
        })
    }

    /**
     * Show Image cropper Panel
     */
    showCropper(event): void {
        this.cropperDialogRef = this._matDialog.open(CommonCropperComponent, {
            disableClose: false,
            width: "650px",
            panelClass: "no-padding-dialog",
        });

        this.cropperDialogRef.componentInstance.fileChangeEvent(event);
        this.cropperDialogRef.componentInstance.tutTitle =
            "Edit Image for banner";
        this.cropperDialogRef.componentInstance.aspectRatio = 1 / 1;
        this.cropperDialogRef.componentInstance.maintainAspectRatio = false;

        // this.cropperDialogRef.componentInstance.tutDesc = '';

        this.cropperDialogRef.afterClosed().subscribe((response) => {
            if (!response) {
                return;
            } else {
                const actionType: string = response[0];
                const croppedFile = response[1];

                let reader = new FileReader();
                let files = croppedFile.file;

                console.log("files", files);

                // reader.onload = (e: any) =>{
                //
                //     this.deal.photosarr.push({value: croppedFile.base64, data: croppedFile.file});
                //
                // }

                // this._customizerService.compressFile(croppedFile.base64).then((res)=>{
                //     const fileobj = res;
                //     console.log("base64ToFile(fileobj.previewUrl)", base64ToFile(fileobj.previewUrl));
                //     this.uploadImageDriverDoc(base64ToFile(fileobj.previewUrl));
                // });

                reader.onload = (e: any) => {
                    console.log("e", e.target.result);
                    // this.uploadImageDriverDoc(base64ToFile(e.target.result));
                    // before values without compress image croppedFile.file croppedFile.base64
                    // this.deal.photosarr.push({value:this.fileArray.previewUrl, data:  this.fileArray.fileArr});
                };

                reader.readAsDataURL(files);
                // this.productForm.markAsDirty();
            }
        });
    }

    getBannersURLs(){
        this._CommonService.getToolkitBanners().subscribe((res:any)=>{
            if(res.response == 1){
                console.log("resgetToolkitBanners", res);
                
                // this.bannerURLData = res.data[0];
                this.staticBannerURL = res.data[0]?.staticBannerUrl;
                this.mobileFriendlyBannersURL = res.data[0]?.mobieFriendlyBannerUrl;
            }else{
                this.bannerURLData = null;
                this._matSnakeBar.open(res.message, 'OKAY', {duration : 2000})
            }
        })
    }
}
