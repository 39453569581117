<div class="container">
    <div class="form-container">
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="input-group">
                <ng-container *ngFor="let input of formInput; index as i">
                    <input size="10" type="text" #formRow formControlName="{{input}}" (paste)="onPaste($event)"
                        class="input-box"
                        maxlength="1" (keydown)="keyDownEvent($event, i, input)" (input)="onInput($event, i)">
                </ng-container>
            </div>
        </form>
    </div>
</div>
