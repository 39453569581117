import { Component, Inject,  OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OtpFormComponent } from "../otp-form/otp-form.component";
import { ThemeService } from "ng2-charts";
import { AuthService } from "app/main/pages/auth.service";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-tfa-verification-dialog",
    templateUrl: "./tfa-verification-dialog.component.html",
    styleUrls: ["./tfa-verification-dialog.component.scss"],
})
export class TfaVerificationDialogComponent implements OnInit {
    qrImage: string = '';
    isAuthSetUp: number;
    otp; 
    isFieldReset: boolean = false;
    @ViewChild("otpForm") otpFormComponent!: OtpFormComponent;




    
    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _auth: AuthService,
        private _progressBar: FuseProgressBarService,
        private _matSnackBar: MatSnackBar,
        public matDialogRef: MatDialogRef<TfaVerificationDialogComponent>,
        private _fb: FormBuilder
    ) {
      
    }

    ngOnInit(): void {
        this.isAuthSetUp = this._data.isAuthSetUp;

        if (!this.isAuthSetUp) {
            this.qrImage = this._data.qrImage;
        } else {
            this.qrImage = null;
        }
    }

    onNext() {
      this.isFieldReset = false;
        if (this.isAuthSetUp == 0) {
            // show enter pin screen
            this.isAuthSetUp = 1;
        } else {
            this.submitOtp();
            this._progressBar.show();
            this._auth.verifyQRCode({"code": Number(this.otp) }).then((res: any) => {
                if (res.response == 1) {
                    this._progressBar.hide();
                    this._matSnackBar.open(res.message, "OKAY", {
                        duration: 3000,
                    });
                    this.matDialogRef.close(true);
                } else {
                    this._progressBar.hide();
                    this._matSnackBar.open(res.message, "OKAY", {
                        duration: 3000,
                    });

                    this.isFieldReset = true;
                    this.isFieldReset = false;
                    // this.matDialogRef.close(false);
                }
            });
        }
       
    }

    onOtpChange(otp) {
        this.otp = otp;
    }

    submitOtp() {
        if (this.otpFormComponent) {
            const otpForm = this.otpFormComponent.form;
            console.log("this.otpFormComponent.formInput", otpForm.value);
            this.otp = this.otpFormComponent.formInput
                .map((input) => otpForm.get(input)?.value)
                .join("");
            console.log("OTP Value:", this.otp);
        }
    }

    get isOtpFormValid(): boolean {
        return this.otpFormComponent
            ? this.otpFormComponent.isFormValid
            : false;
    }
}
